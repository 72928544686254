import merge from 'lodash/merge';
import { TIconSize } from '../BaseIcon';
import { ThemeConfig } from './types';
import { getColor } from '../colors';

const baseIconSizeConfig = {
  large: {
    width: '48px',
    height: '48px',
    iconSize: 'large' as TIconSize,
    iconPadding: '0',
    fontSize: '0',
  },
  medium: {
    width: '40px',
    height: '40px',
    iconSize: 'medium' as TIconSize,
    iconPadding: '0',
    fontSize: '0',
  },
  small: {
    width: '32px',
    height: '32px',
    iconSize: 'small' as TIconSize,
    iconPadding: '0',
    fontSize: '0',
  },
};

const baseThemeConfig = {
  lightConfig: {
    Popper: {
      background: getColor('neutral8'),
      text: getColor('primary1'),
      boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.10)',
    },
    Switch: {
      label: {
        normal: getColor('primary1'),
        disabled: getColor('neutral3'),
        activeDisabled: getColor('neutral3'),
      },
      control: {
        active: {
          border: getColor('secondary1'),
          background: getColor('secondary1'),
        },
        normal: {
          border: getColor('neutral3'),
          background: getColor('neutral3'),
        },
        disabled: {
          border: getColor('primary2'),
          background: getColor('primary2'),
        },
        activeDisabled: {
          border: getColor('shades2'),
          background: getColor('shades2'),
        },
      },
      circle: {
        normal: getColor('neutral6'),
        disabled: getColor('neutral6'),
        activeDisabled: getColor('neutral6'),
      },
    },
    Checkbox: {
      text: {
        normal: getColor('primary1'),
        disabled: getColor('neutral3'),
      },
      checkmark: {
        normal: getColor('neutral6'),
        disabled: getColor('neutral6'),
      },
      normal: getColor('neutral6'),
      accent: getColor('secondary1'),
      disabled: getColor('neutral4'),
    },
    Popover: {
      background: getColor('neutral6'),
      shadow: '0px 4px 10px rgba(0, 0, 0, 0.08)',
      border: '0.5px solid rgba(0, 0, 0, 0.15);',
    },
    ListItem: {
      color: {
        normal: 'transparent',
        hover: getColor('neutral5'),
        focused: getColor('neutral4'),
        disabled: 'transparent',
      },
      text: {
        normal: getColor('primary1'),
        disabled: getColor('neutral3'),
      },
      subtitle: {
        normal: getColor('neutral2'),
        disabled: getColor('neutral3'),
      },
    },
    InputBase: {
      normal: {
        text: getColor('primary1'),
        placeholder: getColor('neutral2'),
      },
      disabled: {
        text: getColor('neutral3'),
        placeholder: getColor('neutral3'),
      },
    },
    InputLabel: {
      normal: getColor('neutral2'),
      active: getColor('primary1'),
      disabled: getColor('neutral3'),
    },
    FieldSet: {
      normal: getColor('neutral3'),
      focused: getColor('shades1'),
      disabled: getColor('neutral3'),
      error: getColor('secondary2'),
      success: getColor('secondary4'),
    },
    HelperText: {
      normal: getColor('primary1'),
      disabled: getColor('neutral3'),
      error: getColor('secondary2'),
      success: getColor('secondary4'),
    },
    Button: {
      primary: {
        normal: {
          background: getColor('primary1'),
          text: getColor('neutral6'),
        },
        hover: {
          background: getColor('secondary1'),
          text: getColor('neutral6'),
        },
        pressed: {
          background: getColor('secondary4'),
          text: getColor('neutral1'),
        },
        disabled: {
          background: getColor('neutral3'),
          text: getColor('neutral4'),
        },
        active: {
          background: getColor('secondary4'),
          text: getColor('neutral1'),
        },
      },
      outline: {
        normal: {
          background: getColor('neutral1'),
          text: getColor('neutral1'),
        },
        hover: {
          background: getColor('secondary1'),
          text: getColor('primary1'),
        },
        pressed: {
          background: getColor('secondary4'),
          text: getColor('neutral1'),
        },
        disabled: {
          background: getColor('neutral3'),
          text: getColor('neutral3'),
        },
        active: {
          background: getColor('secondary4'),
          text: getColor('neutral1'),
        },
      },
      social: {
        normal: {
          background: getColor('neutral1'),
          text: getColor('neutral1'),
        },
        hover: {
          background: getColor('secondary1'),
          text: getColor('neutral1'),
        },
        pressed: {
          background: getColor('shades2'),
          text: getColor('neutral1'),
        },
        disabled: {
          background: getColor('neutral4'),
          text: getColor('neutral2'),
        },
        active: {
          background: getColor('shades2'),
          text: getColor('neutral1'),
        },
      },
      icon1: {
        normal: {
          background: getColor('shades2'),
          text: getColor('primary1'),
        },
        hover: {
          background: getColor('secondary3'),
          text: getColor('primary1'),
        },
        pressed: {
          background: getColor('secondary4'),
          text: getColor('primary1'),
        },
        disabled: {
          background: getColor('neutral4'),
          text: getColor('neutral6'),
        },
        active: {
          background: undefined,
          text: undefined,
        },
      },
      icon2: {
        normal: {
          background: getColor('neutral4'),
          text: getColor('primary1'),
        },
        hover: {
          background: getColor('shades2'),
          text: getColor('primary1'),
        },
        pressed: {
          background: getColor('secondary4'),
          text: getColor('primary1'),
        },
        disabled: {
          background: getColor('neutral4'),
          text: getColor('neutral2'),
        },
        active: {
          background: getColor('neutral4'),
          text: getColor('secondary1'),
        },
      },
      text: {
        normal: {
          background: undefined,
          text: getColor('primary1'),
        },
        hover: {
          background: undefined,
          text: getColor('secondary1'),
        },
        pressed: {
          background: undefined,
          text: getColor('secondary4'),
        },
        disabled: {
          background: undefined,
          text: getColor('neutral3'),
        },
        active: {
          background: undefined,
          text: getColor('secondary4'),
        },
      },
      underlinedText: {
        normal: {
          background: undefined,
          text: getColor('primary1'),
        },
        hover: {
          background: undefined,
          text: getColor('secondary1'),
        },
        pressed: {
          background: undefined,
          text: getColor('secondary4'),
        },
        disabled: {
          background: undefined,
          text: getColor('neutral3'),
        },
        active: {
          background: undefined,
          text: getColor('secondary4'),
        },
      },
    },
    Search: {
      normal: {
        background: getColor('neutral6'),
        border: getColor('neutral3'),
        placeholder: getColor('neutral3'),
        icon: 'primary1',
        text: getColor('primary1'),
      },
      focused: {
        background: getColor('neutral6'),
        border: getColor('secondary1'),
        placeholder: 'transparent',
        icon: 'primary1',
        text: getColor('primary1'),
      },
      disabled: {
        background: getColor('neutral6'),
        border: getColor('neutral3'),
        placeholder: getColor('neutral3'),
        icon: 'neutral3',
        text: getColor('neutral3'),
      },
      dropdown: {
        background: getColor('neutral6'),
        border: getColor('primary2'),
        placeholder: getColor('primary1'),
        icon: 'primary1',
        text: getColor('primary1'),
      },
    },
  },
  darkConfig: {
    Popper: {
      background: getColor('neutral8'),
      text: getColor('primary1'),
      boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.10)',
    },
    Switch: {
      label: {
        normal: getColor('primary2'),
        disabled: getColor('neutral7'),
        activeDisabled: getColor('neutral7'),
      },
      control: {
        active: {
          border: getColor('secondary1'),
          background: getColor('secondary1'),
        },
        normal: {
          border: getColor('neutral7'),
          background: getColor('neutral7'),
        },
        disabled: {
          border: getColor('neutral7'),
          background: getColor('neutral1'),
        },
        activeDisabled: {
          border: getColor('shades2'),
          background: getColor('shades2'),
        },
      },
      circle: {
        normal: getColor('primary2'),
        disabled: getColor('neutral7'),
        activeDisabled: getColor('primary2'),
      },
    },
    Checkbox: {
      text: {
        normal: getColor('primary2'),
        disabled: getColor('neutral7'),
      },
      checkmark: {
        normal: getColor('neutral6'),
        disabled: getColor('primary1'),
      },
      normal: getColor('primary1'),
      accent: getColor('secondary1'),
      disabled: getColor('neutral7'),
    },
    Popover: {
      background: getColor('neutral1'),
      shadow: '0px 4px 15px rgba(0, 0, 0, 0.6)',
      border: 'none',
    },
    ListItem: {
      color: {
        normal: 'transparent',
        hover: getColor('neutral7'),
        focused: getColor('neutral2'),
        disabled: 'transparent',
      },
      text: {
        normal: getColor('primary2'),
        disabled: getColor('neutral7'),
      },
      subtitle: {
        normal: getColor('neutral3'),
        disabled: getColor('neutral7'),
      },
    },
    InputBase: {
      normal: {
        text: getColor('neutral4'),
        placeholder: getColor('neutral4'),
      },
      disabled: {
        text: getColor('neutral3'),
        placeholder: getColor('neutral3'),
      },
    },
    InputLabel: {
      normal: getColor('neutral4'),
      active: getColor('neutral4'),
      disabled: getColor('neutral2'),
    },
    FieldSet: {
      normal: getColor('neutral3'),
      focused: getColor('shades1'),
      disabled: getColor('neutral2'),
      error: getColor('secondary2'),
      success: getColor('secondary4'),
    },
    HelperText: {
      normal: getColor('neutral2'),
      disabled: getColor('neutral2'),
      error: getColor('secondary2'),
      success: getColor('secondary4'),
    },
    Button: {
      primary: {
        normal: {
          background: getColor('primary2'),
          text: getColor('primary1'),
        },
        hover: {
          background: getColor('secondary1'),
          text: getColor('neutral6'),
        },
        pressed: {
          background: getColor('secondary4'),
          text: getColor('neutral1'),
        },
        disabled: {
          background: getColor('neutral2'),
          text: getColor('neutral3'),
        },
        active: {
          background: getColor('secondary4'),
          text: getColor('neutral1'),
        },
      },
      outline: {
        normal: {
          background: getColor('neutral4'),
          text: getColor('neutral4'),
        },
        hover: {
          background: getColor('shades2'),
          text: getColor('shades2'),
        },
        pressed: {
          background: getColor('secondary4'),
          text: getColor('secondary4'),
        },
        disabled: {
          background: getColor('neutral2'),
          text: getColor('neutral2'),
        },
        active: {
          background: getColor('secondary4'),
          text: getColor('secondary4'),
        },
      },
      social: {
        normal: {
          background: getColor('neutral4'),
          text: getColor('neutral4'),
        },
        hover: {
          background: getColor('shades2'),
          text: getColor('shades2'),
        },
        pressed: {
          background: getColor('secondary4'),
          text: getColor('secondary4'),
        },
        disabled: {
          background: getColor('neutral2'),
          text: getColor('neutral2'),
        },
        active: {
          background: getColor('secondary4'),
          text: getColor('secondary4'),
        },
      },
      icon1: {
        normal: {
          background: getColor('shades2'),
          text: getColor('primary1'),
        },
        hover: {
          background: getColor('secondary3'),
          text: getColor('primary1'),
        },
        pressed: {
          background: getColor('secondary4'),
          text: getColor('primary1'),
        },
        disabled: {
          background: getColor('neutral2'),
          text: getColor('neutral3'),
        },
        active: {
          background: undefined,
          text: undefined,
        },
      },
      icon2: {
        normal: {
          background: getColor('neutral4'),
          text: getColor('primary1'),
        },
        hover: {
          background: getColor('shades2'),
          text: getColor('primary1'),
        },
        pressed: {
          background: getColor('secondary4'),
          text: getColor('primary1'),
        },
        disabled: {
          background: getColor('neutral2'),
          text: getColor('neutral3'),
        },
        active: {
          background: getColor('neutral4'),
          text: getColor('shades3'),
        },
      },
      text: {
        normal: {
          background: undefined,
          text: getColor('primary2'),
        },
        hover: {
          background: undefined,
          text: getColor('shades2'),
        },
        pressed: {
          background: undefined,
          text: getColor('shades7'),
        },
        disabled: {
          background: undefined,
          text: getColor('neutral2'),
        },
        active: {
          background: undefined,
          text: getColor('shades7'),
        },
      },
      underlinedText: {
        normal: {
          background: undefined,
          text: getColor('primary2'),
        },
        hover: {
          background: undefined,
          text: getColor('shades2'),
        },
        pressed: {
          background: undefined,
          text: getColor('shades7'),
        },
        disabled: {
          background: undefined,
          text: getColor('neutral2'),
        },
        active: {
          background: undefined,
          text: getColor('shades7'),
        },
      },
    },
    Search: {
      normal: {
        background: getColor('neutral1'),
        border: 'transparent',
        placeholder: getColor('neutral3'),
        icon: 'neutral2',
        text: getColor('neutral6'),
      },
      focused: {
        background: getColor('neutral1'),
        border: getColor('shades2'),
        placeholder: getColor('neutral3'),
        icon: 'neutral2',
        text: getColor('neutral6'),
      },
      disabled: {
        background: getColor('neutral1'),
        border: 'transparent',
        placeholder: getColor('neutral2'),
        icon: 'neutral2',
        text: getColor('neutral2'),
      },
    },
  },
  sizeConfig: {
    Button: {
      primary: {
        large: {
          width: '240px',
          height: '60px',
          iconSize: 'medium' as TIconSize,
          iconPadding: '20px',
          fontSize: '18px',
        },
        medium: {
          width: '240px',
          height: '50px',
          iconSize: 'small' as TIconSize,
          iconPadding: '20px',
          fontSize: '16px',
        },
        small: {
          width: '200px',
          height: '40px',
          iconSize: 'small' as TIconSize,
          iconPadding: '20px',
          fontSize: '14px',
        },
        verySmall: {
          width: '130px',
          height: '24px',
          iconSize: 'extraSmall' as TIconSize,
          iconPadding: '12px',
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '16px',
        },
      },
      outline: {
        large: {
          width: '240px',
          height: '60px',
          iconSize: 'medium' as TIconSize,
          iconPadding: '20px',
          fontSize: '18px',
          borderWidth: '2px',
        },
        medium: {
          width: '240px',
          height: '50px',
          iconSize: 'small' as TIconSize,
          iconPadding: '20px',
          fontSize: '16px',
          borderWidth: '2px',
        },
        small: {
          width: '200px',
          height: '40px',
          iconSize: 'small' as TIconSize,
          iconPadding: '20px',
          fontSize: '14px',
          borderWidth: '2px',
        },
        verySmall: {
          width: '80px',
          height: '24px',
          iconSize: 'extraSmall' as TIconSize,
          iconPadding: '12px',
          fontSize: '12px',
          fontWeight: '500',
          lineHeight: '16px',
          borderWidth: '1px',
        },
      },
      social: {
        large: {
          width: '100px',
          height: '50px',
          iconSize: 'large' as TIconSize,
          iconPadding: '0',
          fontSize: '0',
        },
        medium: {
          width: '100px',
          height: '50px',
          iconSize: 'large' as TIconSize,
          iconPadding: '0',
          fontSize: '0',
        },
        small: {
          width: '100px',
          height: '50px',
          iconSize: 'large' as TIconSize,
          iconPadding: '0',
          fontSize: '0',
        },
      },
      icon1: baseIconSizeConfig,
      icon2: baseIconSizeConfig,
      text: {
        large: {
          width: 'auto',
          height: '46px',
          iconSize: 'extraLarge' as TIconSize,
          iconPadding: '14px',
          fontSize: '40px',
          fontWeight: '600',
          fontFamily: 'Rebond',
        },
        medium: {
          width: 'auto',
          height: '34px',
          iconSize: 'medium' as TIconSize,
          iconPadding: '10px',
          fontSize: '30px',
          fontWeight: '600',
          fontFamily: 'Rebond',
        },
        small: {
          width: 'auto',
          height: '24px',
          iconSize: 'small' as TIconSize,
          iconPadding: '5px',
          fontSize: '16px',
        },
      },
      underlinedText: {
        large: {
          width: 'auto',
          height: '50px',
          iconSize: 'extraLarge' as TIconSize,
          iconPadding: '14px',
          fontSize: '40px',
          fontWeight: '600',
          fontFamily: 'Rebond',
        },
        medium: {
          width: 'auto',
          height: '34px',
          iconSize: 'extraLarge' as TIconSize,
          iconPadding: '14px',
          fontSize: '30px',
          fontWeight: '600',
          fontFamily: 'Rebond',
        },
        small: {
          width: 'auto',
          height: '24px',
          iconSize: 'small' as TIconSize,
          iconPadding: '5px',
          fontSize: '16px',
        },
        semiSmall: {
          width: 'auto',
          height: '20px',
          iconSize: 'extraSmall' as TIconSize,
          iconPadding: '3px',
          fontSize: '14px',
          fontWeight: '500',
        },
        verySmall: {
          width: 'auto',
          height: '16px',
          iconSize: 'extraSmall' as TIconSize,
          iconPadding: '3px',
          fontSize: '12px',
          fontWeight: '600',
          lineHeight: '16px',
          letterSpacing: '0.02em',
        },
      },
    },
    CardMaker: {
      large: {
        iconSize: 'small' as TIconSize,
        textVariant: 'labelSmallUppercaseBold',
        gap: '8px',
      },
      medium: {
        iconSize: 'extraSmall' as TIconSize,
        textVariant: 'labelExtraSmallUppercase',
        gap: '4px',
      },
    },
  },
};

export function createThemeConfig(themeModifier: ThemeConfig | undefined): ThemeConfig {
  return merge({}, baseThemeConfig, themeModifier) as ThemeConfig;
}
