import React, { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import Loader from '../Loader';
import { ButtonProps } from './Button.types';
import { getComponent, getIconWrapper } from './utils';
import { ChildrenWrapper } from './Button.styles';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    children,
    disabled = false,
    active = false,
    size = 'large',
    type = 'primary',
    iconPosition = 'left',
    Icon,
    fullWidth = false,
    href,
    buttonType,
    loading = false,
    bMargin = 0,
    lMargin = 0,
    rMargin = 0,
    tMargin = 0,
    ...props
  },
  ref
) {
  const { sizeConfig, color: colorConfigKey, ...theme } = useContext(ThemeContext);

  const handleClick = (e: React.MouseEvent<HTMLElement>) =>
    typeof props.onClick === 'function' && props.onClick(e);

  const Component = useMemo(() => getComponent(type), [type]);
  const IconWrapper = useMemo(() => getIconWrapper(type), [type]);

  const sConf = useMemo(
    () => sizeConfig.Button?.[type]?.[size] || sizeConfig.Button?.[type].large,
    [sizeConfig, size, type]
  );

  const cConf = useMemo(() => theme[colorConfigKey].Button[type], [colorConfigKey, theme, type]);

  const isPrimaryOrOutline = useMemo(() => ['primary', 'outline'].includes(type), [type]);

  const contentRenderer = () => {
    if (['primary', 'outline', 'social'].includes(type) && loading) {
      return <Loader width="94px" height="30px" />;
    }

    if (['icon1', 'icon2', 'social'].includes(type)) {
      return <Icon size={sConf.iconSize} color="currentColor" />;
    }

    return (
      <>
        {Icon && (
          <IconWrapper leftPosition={iconPosition === 'left'} sConf={sConf}>
            <Icon size={sConf.iconSize} color="currentColor" />
          </IconWrapper>
        )}
        <ChildrenWrapper fixedWidth={!!Icon && isPrimaryOrOutline}>{children}</ChildrenWrapper>
      </>
    );
  };

  return (
    <Component
      onClick={handleClick}
      active={active}
      disabled={disabled}
      loading={loading}
      href={href}
      cConf={cConf}
      sConf={sConf}
      fullWidth={fullWidth}
      ref={ref}
      type={buttonType}
      bMargin={bMargin}
      lMargin={lMargin}
      rMargin={rMargin}
      tMargin={tMargin}
      {...props}
    >
      {contentRenderer()}
    </Component>
  );
});

export default Button;
