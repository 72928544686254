import { Typography } from '@karma/components';
import { LeftArrowIcon } from '@karma/components/icons';
import { useTranslation } from 'react-i18next';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import useGetSignUpFormProps from 'hooks/useGetSignUpFormProps';
import FormEmailPassword from '../FormEmailPassword/FormEmailPassword';
import { ArrowButton, ContentContainer } from './SignUpPasswordContainer.style';

const SignUpPasswordContainer = ({
  goBack,
  authorizationHandler,
  isGuestSignup,
  closeModal,
  titleText = 'app:signUpWithEmail',
  buttonText = 'app:signUp',
}) => {
  const { t } = useTranslation();
  const { formProps, fetching, onSubmit } = useGetSignUpFormProps(closeModal, isGuestSignup, authorizationHandler);
  const contextDataAttrs = Alooma.useContextDataAttrs();

  return (
    <ContentContainer fullWidth direction="column" spacing={28}>
      <ArrowButton
        onClick={goBack}
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.LINK_GO_BACK}
        className="back-button"
      >
        <LeftArrowIcon size="large" />
      </ArrowButton>
      <Typography variant="h3">{t(titleText)}</Typography>
      <FormEmailPassword
        onSubmit={onSubmit}
        formProps={formProps}
        primaryButton={{
          text: t(buttonText),
          'data-alooma-element': ALOOMA.ELEMENTS.JOIN_US,
          loading: fetching,
          size: 'small',
        }}
      />
    </ContentContainer>
  );
};

export default SignUpPasswordContainer;
