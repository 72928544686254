import moment from 'moment';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import { v4 as uuidv4 } from 'uuid';
import { getQueryParams } from 'functions/urlHelpers';
import isValidUrl from 'functions/isValidUrl';
import { getCookieValue, setCookie } from 'functions/cookies';
import * as ALOOMA from 'consts/alooma';
import getBrowser from 'functions/getBrowser';
import getOrigin from 'services/alooma/getOrigin';

function getAloomaData() {
  const key = `mp_${process.env.REACT_APP_IKNOWLOGY_TOKEN}_alooma`;
  const rawData = getCookieValue(key);

  if (!rawData || typeof rawData !== 'string') {
    return null;
  }

  const data = decodeURIComponent(rawData);

  try {
    return JSON.parse(data);
  } catch (e) {
    return data || null;
  }
}

function setAloomaData(rawData) {
  const key = `mp_${process.env.REACT_APP_IKNOWLOGY_TOKEN}_alooma`;
  const data = (() => {
    if (!rawData) return null;
    if (typeof rawData === 'object') return encodeURIComponent(JSON.stringify(rawData));

    return encodeURIComponent(String(rawData));
  })();
  const expireTime = moment().add(1, 'years').toDate().toUTCString();
  const other = {
    domain: window.location.hostname.includes('localhost') ? 'localhost' : '.karmanow.com',
    path: '/',
  };

  setCookie(key, data, expireTime, other);
}

function getReferrerParams() {
  if (!isValidUrl(document.referrer)) {
    return {
      $initial_referrer: '$direct',
      $initial_referring_domain: '$direct',
    };
  }

  return {
    $initial_referrer: document.referrer,
    $initial_referring_domain: new URL(document.referrer).hostname,
  };
}

function getSearchEngine() {
  const searchEngine = (() => {
    if (!document.referrer) return null;
    if (document.referrer.search('https?://(.*)google.([^/?]*)') === 0) return 'google';
    if (document.referrer.search('https?://(.*)bing.com') === 0) return 'bing';
    if (document.referrer.search('https?://(.*)yahoo.com') === 0) return 'yahoo';
    if (document.referrer.search('https?://(.*)duckduckgo.com') === 0) return 'duckduckgo';

    return null;
  })();

  return searchEngine ? { $search_engine: searchEngine } : {};
}

function getSearchParams() {
  const params = getQueryParams(['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']);

  return omitBy(params, isNull);
}

// the logic is taken from https://shoptagrapp.s3-eu-west-1.amazonaws.com/jssdk-alooma.js
function createAloomaData() {
  const referrerParams = getReferrerParams();
  const searchEngine = getSearchEngine();
  const searchParams = getSearchParams();
  const aloomaData = {
    distinct_id: uuidv4(),
    ...referrerParams,
    ...searchEngine,
    ...searchParams,
  };

  setAloomaData(aloomaData);

  return aloomaData;
}

const getCustomDistinctId = () => {
  // first version of distinct id
  const distinctId = localStorage.getItem('distinct_id');

  if (distinctId) return distinctId;

  // alooma storage
  const aloomaData = getAloomaData();

  if (aloomaData?.distinct_id) return aloomaData.distinct_id;

  // imitate alooma storage
  const newAloomaData = createAloomaData();

  return newAloomaData?.distinct_id;
};

function buildCustomEvent(name, data) {
  const event = {
    ...data,
    [ALOOMA.PARAMS.EVENT_NAME]: name,
    [ALOOMA.PARAMS.USER_ID]: localStorage.getItem('user_id') || 'null',
    [ALOOMA.PARAMS.BROWSER]: getBrowser(),
    [ALOOMA.PARAMS.ALOOMA_TOKEN]: process.env.REACT_APP_IKNOWLOGY_TOKEN,
    [ALOOMA.PARAMS.TIMESTAMP]: new Date().getTime(),
    [ALOOMA.PARAMS.DISTINCT_ID]: getCustomDistinctId(),
    [ALOOMA.PARAMS.ORIGIN]: getOrigin(),
    [ALOOMA.PARAMS.URL]: window.location.href,
    [ALOOMA.PARAMS.VERSION]: ALOOMA.VERSION,
  };

  return Object.entries(event).map(([key, value]) => ({ name: key, value }));
}

export default buildCustomEvent;
