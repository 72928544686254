/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { Button } from '@karma/components';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import OAuth2 from 'functions/OAuth2';
import Alooma from 'services/alooma';
import { oauthProviders } from 'consts/oauthProviders';

const SocialLogin = ({
  disabled,
  agreementValue,
  setAgreementError,
  redirectTo,
  googleProps,
  facebookProps,
  appleProps,
}) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const handleClick = provider => {
    if (!agreementValue) setAgreementError();
    else OAuth2.login({ provider, redirectTo });
  };

  const getProviderProps = provider => {
    if (provider === 'google') return googleProps;
    if (provider === 'facebook') return facebookProps;
    if (provider === 'apple') return appleProps;

    return {};
  };

  return (
    <>
      {oauthProviders.map(([provider, IconComponent]) => (
        <Button
          key={provider}
          Icon={IconComponent}
          {...contextDataAttrs}
          data-alooma-element={provider}
          onClick={() => handleClick(provider)}
          disabled={disabled}
          fullWidth
          size="small"
          {...getProviderProps(provider)}
        >
          {t('app:continueWith', {
            provider: capitalize(provider),
          })}
        </Button>
      ))}
    </>
  );
};

SocialLogin.propTypes = {
  disabled: PropTypes.bool,
  agreementValue: PropTypes.bool,
  setAgreementError: PropTypes.func,
  redirectTo: PropTypes.string,
  googleProps: PropTypes.object,
  facebookProps: PropTypes.object,
  appleProps: PropTypes.object,
};

SocialLogin.defaultProps = {
  disabled: false,
  agreementValue: true,
  setAgreementError: () => null,
  redirectTo: null,
  googleProps: {},
  facebookProps: {},
  appleProps: {},
};

export default SocialLogin;
