import { Typography } from '@karma/components';
import { LeftArrowIcon } from '@karma/components/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import useGetLogInFormProps from 'hooks/useGetLogInFormProps';
import FormEmailPassword from '../FormEmailPassword/FormEmailPassword';
import ForgotPasswordFormV3 from '../ForgotPasswordFormV3/ForgotPasswordFormV3';
import { ArrowButton, ContentContainer } from './LogInPasswordContainer.style';

const LogInPasswordContainer = ({
  goBack,
  authorizationHandler,
  defaultEmail,
  closeModal,
  titleText = 'app:logInWithEmail',
  buttonText = 'app:logIn',
}) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const [resetState, setResetState] = useState(false);
  const [instructionsSent, setInstructionsSent] = useState(false);
  const { formProps, fetching, onSubmit } = useGetLogInFormProps(closeModal, defaultEmail, authorizationHandler);

  return (
    <ContentContainer fullWidth direction="column" spacing={28}>
      {!instructionsSent && (
        <>
          <ArrowButton
            onClick={goBack}
            {...contextDataAttrs}
            data-alooma-element={ALOOMA.ELEMENTS.LINK_GO_BACK}
            className="back-button"
          >
            <LeftArrowIcon size="large" />
          </ArrowButton>
          <Typography variant="h3">{resetState ? t('app:forgotPassword') : t(titleText)}</Typography>
        </>
      )}

      {resetState ? (
        <ForgotPasswordFormV3 instructionsSent={instructionsSent} setInstructionsSent={setInstructionsSent} />
      ) : (
        <FormEmailPassword
          onSubmit={onSubmit}
          formProps={formProps}
          primaryButton={{
            text: t(buttonText),
            'data-alooma-element': ALOOMA.ELEMENTS.LOG_IN,
            loading: fetching,
            size: 'small',
          }}
          secondaryButton={{
            text: t('app:forgotPassword'),
            'data-alooma-element': ALOOMA.ELEMENTS.FORGOT_PASSWORD,
            disabled: fetching,
            onClick: () => setResetState(true),
          }}
        />
      )}
    </ContentContainer>
  );
};

export default LogInPasswordContainer;
