import styled from 'styled-components';
import CheckmarkIcon from '../icons/CheckmarkIcon';
import { CheckboxRootProps, CheckmarkIconProps, CheckmarkWrapperProps } from './Checkbox.types';
import { ParagraphMediumStyles } from '../Typography/Typography.styles';
import { getColor } from '../colors';

export const CheckboxRoot = styled.label<CheckboxRootProps>`
  box-sizing: border-box;
  display: inline-flex;
  position: relative;
  flex-direction: column;

  margin: 0;

  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  user-select: none;

  color: ${({ disabled, theme }) => {
    const colors = theme[theme.color].Checkbox.text;

    return disabled ? colors.disabled : colors.normal;
  }};
`;

export const CheckboxContainer = styled.div<CheckboxRootProps>`
  display: inline-flex;
  box-sizing: border-box;
  position: relative;
  width: fit-content;
  user-select: none;
`;

export const CheckmarkWrapper = styled.div<CheckmarkWrapperProps>`
  box-sizing: border-box;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;

  border-width: 1px;
  border-style: solid;
  border-color: ${({ disabled, error, theme }) => {
    const colors = theme[theme.color].Checkbox;

    if (error) return getColor('secondary2');
    if (disabled) return colors.disabled;
    return colors.accent;
  }};
  border-radius: 4px;

  background-color: ${({ disabled, checked, theme }) => {
    const colors = theme[theme.color].Checkbox;

    if (checked) return disabled ? colors.disabled : colors.accent;

    return colors.normal;
  }};

  &:focus {
    outline: none;
    border-width: 1.5px;
  }

  transition: background-color 0.3s ease-in-out, border-width 0.3s ease-in-out;
`;

export const StyledCheckmarkIcon = styled<CheckmarkIconProps>(CheckmarkIcon)`
  opacity: ${({ checked }) => (checked ? 1 : 0)};
  color: ${({ disabled, theme }) => {
    const colors = theme[theme.color].Checkbox.checkmark;

    return disabled ? colors.disabled : colors.normal;
  }};
`;

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const CheckboxLabel = styled.span`
  ${ParagraphMediumStyles};
  padding-left: 12px;
  color: inherit;
`;
