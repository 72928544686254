import { csrLazyWithRetry, ssrLazyWithRetry } from 'functions/lazyImport';

// CSR pages
export const CSR_ROUTES = {
  HomePage: csrLazyWithRetry(() => import(/* webpackChunkName: "HomePage" */ 'components/pages/HomePage/HomePage')),
  HowItWorksPage: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "HowItWorksPage" */ 'components/pages/HowItWorksPage/HowItWorksPage'),
  ),
  MySavedItemsPage: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "MySavedItemsPage" */ 'components/pages/MySavedItemsPage/MySavedItemsPage'),
  ),
  ProfilePage: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "ProfilePage" */ 'components/pages/ProfilePage/ProfilePage'),
  ),
  StoresPage: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "StoresPage" */ 'components/pages/StoresPage/StoresPage'),
  ),
  CouponsPage: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "CouponsPage" */ 'components/pages/CouponsPage/CouponsPage'),
  ),
  CouponsSeeAll: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "CouponsSeeAll" */ 'components/pages/CouponsSeeAll/CouponsSeeAll'),
  ),
  RetailersSeeAllPage: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "RetailersSeeAllPage" */ 'components/pages/RetailersSeeAllPage/RetailersSeeAllPage'),
  ),
  NewSalesPage: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "NewSalesPage" */ 'components/pages/NewSalesPage/NewSalesPage'),
  ),
  RedirectPage: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "RedirectPage" */ 'components/pages/RedirectPage/RedirectPage'),
  ),
  LogoutAndLoginPage: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "LogoutAndLoginPage" */ 'components/pages/LogoutAndLoginPage/LogoutAndLoginPage'),
  ),
  ErrorPage: csrLazyWithRetry(() => import(/* webpackChunkName: "ErrorPage" */ 'components/pages/ErrorPage/ErrorPage')),
  UnsubscribeTagPage: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "UnsubscribeTagPage" */ 'components/pages/UnsubscribeTagPage/UnsubscribeTagPage'),
  ),
  DashboardRetailersPage: csrLazyWithRetry(() =>
    import(
      /* webpackChunkName: "DashboardRetailersPage" */ 'components/pages/DashboardRetailersPage/DashboardRetailersPage'
    ),
  ),
  DashboardRetailerPage: csrLazyWithRetry(() =>
    import(
      /* webpackChunkName: "DashboardRetailerPage" */ 'components/pages/DashboardRetailerPage/DashboardRetailerPage'
    ),
  ),
  OAuthPage: csrLazyWithRetry(() => import(/* webpackChunkName: "OAuthPage" */ 'components/pages/OAuthPage/OAuthPage')),
  OnboardingExtensionSignup: csrLazyWithRetry(() =>
    import(
      /* webpackChunkName: "OnboardingExtensionSignup" */ 'components/pages/OnboardingExtensionSignup/OnboardingExtensionSignup'
    ),
  ),
  OnboardingSafariStep1Page: csrLazyWithRetry(() =>
    import(
      /* webpackChunkName: "OnboardingSafariStep1Page" */ 'components/pages/OnboardingSafariStep1Page/OnboardingSafariStep1Page'
    ),
  ),
  OnboardingSafariStep2Page: csrLazyWithRetry(() =>
    import(
      /* webpackChunkName: "OnboardingSafariStep2Page" */ 'components/pages/OnboardingSafariStep2Page/OnboardingSafariStep2Page'
    ),
  ),
  OnboardingSafariSignUpPage: csrLazyWithRetry(() =>
    import(
      /* webpackChunkName: "OnboardingSafariSignUpPage" */ 'components/pages/OnboardingSafariSignUpPage/OnboardingSafariSignUpPage'
    ),
  ),
  DesktopSweLogin: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "DesktopSweLogin" */ 'components/pages/DesktopSweLogin/DesktopSweLogin'),
  ),
  OnboardingV2ExtensionPage: csrLazyWithRetry(() =>
    import(
      /* webpackChunkName: "OnboardingV2ExtensionPage" */ 'components/pages/OnboardingV2ExtensionPage/OnboardingV2ExtensionPage'
    ),
  ),
  UnsubscribePage: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "UnsubscribePage" */ 'components/pages/UnsubscribePage/UnsubscribePage'),
  ),
  UnsubscribeResult: csrLazyWithRetry(() =>
    import(
      /* webpackChunkName: "UnsubscribeResultPage" */ 'components/pages/UnsubscribeResultPage/UnsubscribeResultPage'
    ),
  ),
  OnboardingIOSExt: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "OnboardingIOSExt" */ 'components/pages/OnboardingIOSExt/OnboardingIOSExt'),
  ),
  MuteTagPage: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "MuteTagPage" */ 'components/pages/MuteTagPage/MuteTagPage'),
  ),
  UsedDataDeletedPage: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "UsedDataDeletedPage" */ 'components/pages/UsedDataDeletedPage/UsedDataDeletedPage'),
  ),
  GlobalSearchResultsPage: csrLazyWithRetry(() =>
    import(
      /* webpackChunkName: "GlobalSearchResultsPage" */ 'components/pages/GlobalSearchResultsPage/GlobalSearchResultsPage'
    ),
  ),
  WebsiteLandingKarmaForMen: csrLazyWithRetry(() =>
    import(
      /* webpackChunkName: "WebsiteLandingKarmaForMen" */ 'components/pages/WebsiteLandingKarmaForMen/WebsiteLandingKarmaForMen'
    ),
  ),
  WebsiteLandingKarma: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "WebsiteLandingKarma" */ 'components/pages/WebsiteLandingKarma/WebsiteLandingKarma'),
  ),
  PartnershipsPage: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "PartnershipsPage" */ 'components/pages/PartnershipsPage/PartnershipsPage'),
  ),
  ExtensionDeletedPage: csrLazyWithRetry(() =>
    import(/* webpackChunkName: "ExtensionDeletedPage" */ 'components/pages/ExtensionDeletedPage/ExtensionDeletedPage'),
  ),
  MobileLoginRedirectPage: csrLazyWithRetry(() =>
    import(
      /* webpackChunkName: "MobileLoginRedirectPage" */ 'components/pages/MobileLoginRedirectPage/MobileLoginRedirectPage'
    ),
  ),
};

// SSR pages
export const SSR_ROUTES = {
  AboutUsPage: ssrLazyWithRetry(() =>
    import(/* webpackChunkName: "AboutUsPage" */ 'components/pages/AboutUsPage/AboutUsPage'),
  ),
  CareersPage: ssrLazyWithRetry(() =>
    import(/* webpackChunkName: "CareersPage" */ 'components/pages/CareersPage/CareersPage'),
  ),
  ContactUsPage: ssrLazyWithRetry(() =>
    import(/* webpackChunkName: "ContactUsPage" */ 'components/pages/ContactUsPage/ContactUsPage'),
  ),
  GetTheButtonPage: ssrLazyWithRetry(() =>
    import(/* webpackChunkName: "GetTheButtonPage" */ 'components/pages/GetTheButtonPage/GetTheButtonPage'),
  ),
  PrivacyPage: ssrLazyWithRetry(() =>
    import(/* webpackChunkName: "PrivacyPage" */ 'components/pages/PrivacyPage/PrivacyPage'),
  ),
  ComeetPrivacyPage: ssrLazyWithRetry(() =>
    import(/* webpackChunkName: "ComeetPrivacyPage" */ 'components/pages/ComeetPrivacyPage/ComeetPrivacyPage'),
  ),
  SharedListPage: ssrLazyWithRetry(() =>
    import(/* webpackChunkName: "SharedListPage" */ 'components/pages/SharedListPage/SharedListPage'),
  ),
  SEOShopPage: ssrLazyWithRetry(() =>
    import(/* webpackChunkName: "SEOShopPage" */ 'components/pages/SEOShopPage/SEOShopPage'),
  ),
  SEOTagPage: ssrLazyWithRetry(() =>
    import(/* webpackChunkName: "SEOTagPage" */ 'components/pages/SEOTagPage/SEOTagPage'),
  ),
  TermsPage: ssrLazyWithRetry(() => import(/* webpackChunkName: "TermsPage" */ 'components/pages/TermsPage/TermsPage')),
  WebsiteRetailerPage: ssrLazyWithRetry(() =>
    import(/* webpackChunkName: "WebsiteRetailerPage" */ 'components/pages/WebsiteRetailerPage/WebsiteRetailerPage'),
  ),
  WebsiteRetailersPage: ssrLazyWithRetry(() =>
    import(/* webpackChunkName: "WebsiteRetailersPage" */ 'components/pages/WebsiteRetailersPage/WebsiteRetailersPage'),
  ),
  WebsiteLandingKarmaMarketing: ssrLazyWithRetry(() =>
    import(
      /* webpackChunkName: "WebsiteLandingKarmaMarketing" */ 'components/pages/WebsiteLandingKarmaMarketing/WebsiteLandingKarmaMarketing'
    ),
  ),
  WebsiteLandingKarmaOld: ssrLazyWithRetry(() =>
    import(
      /* webpackChunkName: "WebsiteLandingKarmaOld" */ 'components/pages/WebsiteLandingKarmaOld/WebsiteLandingKarmaOld'
    ),
  ),
  WebsiteLandingKarmaAI: ssrLazyWithRetry(() =>
    import(
      /* webpackChunkName: "WebsiteLandingKarmaAI" */ 'components/pages/WebsiteLandingKarmaAI/WebsiteLandingKarmaAI'
    ),
  ),
};
