import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as userActions from 'store/user/actions';
import useAction from 'hooks/useAction';
import { guestSignUpUser } from 'functions/authentication/signUpFunctions';
import { showErrorAlert } from 'store/alerts/actions';
import { convertUserFromGuest } from 'functions/authentication/guestConvertFunctions';
import getIsSeoPage from 'functions/getIsSeoPage';
import { trackUserSignUpOnboarding } from 'functions/trackGoogleTagEvent';
import getEmailPasswordFormSchema from 'functions/getEmailPasswordFormSchema';
import { PATHS } from 'consts/paths';

const useGetSignUpFormProps = (callback = () => {}, isGuestSignup, authorizationHandler) => {
  const { t } = useTranslation();
  const fetchUser = useAction(userActions.fetchUser);
  const [fetching, setFetching] = useState(false);
  const showErrorAlertFunc = useAction(showErrorAlert);
  const isSeoPage = getIsSeoPage();
  const schema = getEmailPasswordFormSchema({ t, signUp: true });

  const formProps = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = formProps;

  const showError = err => showErrorAlertFunc({ message: err });

  const signUp = ({ email, password }) => {
    setFetching(true);

    if (isGuestSignup) {
      return convertUserFromGuest({ email, password })
        .then(user => {
          if (typeof authorizationHandler === 'function') {
            authorizationHandler(user);
          } else {
            fetchUser();
            window.location.reload();
          }
        })
        .catch(error => {
          showError(error);
          setFetching(false);
        });
    }

    return guestSignUpUser({ email, password })
      .then(async ({ isGuest, data }) => {
        if (typeof authorizationHandler === 'function') {
          authorizationHandler(data);

          return;
        }

        if (isGuest) return;

        if (isSeoPage) {
          fetchUser();
          callback();
        } else {
          trackUserSignUpOnboarding(email);
          window.location.href = PATHS.DASHBOARD;
        }
      })
      .catch(showError)
      .finally(() => setFetching(false));
  };

  const onSubmit = handleSubmit(signUp);

  return {
    formProps,
    fetching,
    onSubmit,
  };
};

export default useGetSignUpFormProps;
