import { Stack } from '@karma/components';
import styled from 'styled-components';

export const ContentContainer = styled(Stack)`
  position: relative;
  height: 100%;
`;

export const ArrowButton = styled.button`
  all: unset;
  cursor: pointer;
`;
