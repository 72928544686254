/* eslint-disable no-template-curly-in-string */
export default {
  featured: 'Destacado',
  popular: 'Popular',
  couponsAndDeals: 'Cupones y ofertas',
  yourFavorites: 'Tus Favoritos',
  coupons: 'Cupones',
  headerCoupons: 'Karma | Cupones',
  lastSaved: 'Última actualización',
  favorites: 'Favoritos',
  trending: 'Popular',
  weAreLoving: 'Nos encanta',
  karmaHome: 'Karma | Inicio',
  goodMorning: 'Buenos días',
  goodAfternoon: 'Buenas tardes',
  goodEvening: 'Buenas noches',
  addFavoriteStores: 'Añadir tiendas favoritas',
  nothingFound: 'No se encontró nada',
  number: '{{value, number}}',
  clearNumber: '{{value, clearNumber}}',
  saveSettings: 'Guardar Ajustes',
  enableBrand: 'Habilitar {{name}}',
  chrome: 'Chrome',
  edge: 'Edge',
  firefox: 'Firefox',
  ie: 'Internet Explorer',
  oldEdge: 'Edge',
  opera: 'Opera',
  safari: 'Safari',
  remove: 'Eliminar',
  sponsored: 'Patrocinado',
  seeAll: 'Ver todo',
  favorite: 'Favoritos',
  onSale: 'En oferta',
  lowInStock: 'Pocas existencias',
  backInStock: 'Disponible de nuevo',
  coupon: 'Cupón',
  soldOut: 'Agotado',
  fulfilled: 'Ejecutado',
  deal: 'Oferta',
  price: '{{currency}}{{value, number(minimumFractionDigits: 2)}}',
  dayRemaining: 'Queda {{count, number}} día',
  dayRemaining_plural: '{Quedan {count, number}} días',
  karmaStores: 'Tiendas Karma Cash',
  priceLeftToWithdraw: 'Solo queda {{currency}}{{value, number}} para que puedas retirar tus recompensas.',
  fullPrice: '{{value, number}}{{currency}}.{{fraction, fraction}}',
  fraction: '.{{value, fraction}}',
  pendingCash: 'Dinero pendiente',
  payouts: 'Pagos',
  connectWithPayPal: 'Conectar con PayPal',
  selectCause: 'Seleccionar Causa',
  dayBetweenPayouts: 'El dinero puede retirarse {{count, number}} día tras confirmarse la compra.',
  dayBetweenPayouts_plural: 'El dinero puede retirarse {{count, number}} días tras confirmarse la compra.',
  list: 'Lista:',
  emptyWallet: 'Tu cartera está vacía',
  startShoppingToEarn: 'Compra ya para ganar recompensas.',
  withdraw: 'Retirar',
  yourDonations: 'Tus donaciones',
  outOfAmount: 'De {{value, number}}{{currency}}',
  gettingCloser: '¡Te estás acercando!',
  canWithdrawEarnings: 'Ya puedes retirar tus ganancias.',
  workedAt: 'Funcionó {{date}}',
  copied: '¡Copiado!',
  getCoupon: 'Obtener cupón',
  shopNow: 'Comprar ahora',
  karmaLogo: 'Logotipo de Karma',
  stores: 'Tiendas',
  myLists: 'Mis listas',
  uppercase: '{{text, uppercase}}',
  capitalize: '{{text, capitalize}}',
  all: 'Todo',
  lastWeek: 'Semana pasada',
  lastMonth: 'Mes pasado',
  lastThreeMonths: 'Últimos 3 meses',
  clickID: 'Pulsa ID:',
  date: 'Fecha',
  time: 'Hora',
  retailer: 'Minorista',
  orderID: 'ID pedido',
  startShoppingToEarnKarmaCash: 'Empieza a comprar para ganar Karma Cash,',
  browseStores: 'busca tiendas',
  doNotHaveClicks: 'No tienes ningún clic en este periodo.',
  doNotHaveTransactions: 'No tiene ninguna transacción en este periodo.',
  payPalAlt: 'PayPal',
  payPal: 'PayPal:',
  welcomeBonus: '¡Bono de bienvenida!',
  karmaGives: '¡Karma Gives!',
  karmaGivesRefund: '¡Reembolso Karma Gives!',
  completed: 'Completado',
  notQualified: 'No califica',
  pending: 'Pendiente',
  cancelled: 'Cancelado',
  paid: 'Pagado',
  empty: 'Vacío',
  expired: 'Caducado',
  discoverAndShop: 'Discover & Shop',
  contactUs: 'Contacto',
  news: 'Novedades',
  faq: 'FAQ',
  aboutUs: 'Quiénes somos',
  karmaItem: 'Artículo Karma',
  email: 'Email',
  congrats: '¡Felicidades!',
  congratulations: '¡Enhorabuena!',
  getKarmaButton: 'Obtener Botón Karma',
  saveThingsToBuy: '¡Guarda lo que quieras comprar luego con Karma!',
  savedFirstItem: '¡Has guardado tu primer artículo!',
  getTheButton: 'Obtén el botón',
  savedFirstItem_plural: '¡Ya has guardado artículos!',
  iWantToSeeOther: 'Quiero ver otras <1>tiendas asociadas</1>',
  neverMissProducts: 'No te pierdas los productos en oferta o de nuevo disponible.',
  shareByEmail: 'Compartir por email',
  whenIFindSmth: 'Cuando encuentro algo que quiero, lo guardo en Karma.',
  completeSignUp: 'Registro completo',
  addToList: 'Añadir a la lista',
  chat: 'Chat',
  karmaCash: 'Karma Cash',
  clickToSaveItem: 'Haz clic para guardar un artículo',
  createListAndPressEnter: 'Crea lista y pulsa «Enter»',
  confirmDeleteProduct: '¿Seguro que quieres eliminar este producto?',
  shareOnFacebook: 'Compartir en Facebook',
  selectAll: 'Seleccionar Todo',
  selectProp: 'Seleccionar un {{name}}',
  delete: 'Eliminar',
  fulfill: 'Ejecutar',
  mute: 'Silenciar',
  anyPriceChange: 'Cualquier cambio de precio',
  priceOff: '{{value, number}} % dto.',
  notifyWhen: 'Avisarme cuando',
  tweetOnTwitter: 'Tuitear en Twitter',
  shareProduct: 'Compartir producto',
  markAsFulfilled: 'Marcar como Ejecutado',
  unmuteNotifications: 'Desactivar notificaciones',
  muteNotifications: 'Silenciar notificaciones',
  priceEvolution: 'Evolución del precio',
  logOut: 'Cerrar sesión',
  twitter: 'Twitter',
  facebook: 'Facebook',
  instagram: 'Instagram',
  whatsapp: 'WhatsApp',
  messenger: 'Messenger',
  privacyPolicy: 'Política de privacidad',
  confirmDelete: '¿Seguro que quieres eliminar estos productos?',
  termsAndConditions: 'Términos y condiciones',
  logosProvidedBy: 'Logotipos facilitados por {{name}}',
  copyright: 'Copyright © {{year, clearNumber}} Karma Shopping Ltd., como Karma. Todos los derechos reservados.',
  noNotifications: 'Sin notificaciones',
  clearAllNotifications: 'Borrar las notificaciones',
  areYouSureRemoveNotifications: '¿Seguro que quieres eliminar todas las notificaciones?',
  deleteProducts: 'Eliminar productos',
  no: 'No',
  yes: 'Sí',
  headerStores: ' Karma | Tiendas',
  clearNotification: 'Borrar notificaciones',
  clear: 'Eliminar',
  bell: 'campana',
  remindInMinute: 'Recordármelo en {{value, number}} minuto',
  remindInMinute_plural: 'Recordármelo en {{value, number}} minutos',
  remindInHour: 'Recordármelo en {{value, number}} hora',
  remindInHour_plural: 'Recordármelo en {{value, number}} horas',
  retailers: 'Minoristas',
  claimKarmaCash: 'Reclama hasta un {{percent, number}} % de Karma Cash',
  clickOnClaimButton: 'Pulsa «Reclamar hasta un {{percent, number}} % de Karma Cash».',
  goShopping: 'Comprar',
  rackUpCash: 'Acumular dinero',
  getPaidDirectly: 'Recibir pago directamente',
  earnRealMoney: 'Gana dinero real en cada compra.',
  trackYourEarnings: 'Controla tus ganancias y cobra cada temporada.',
  ordinalNumber: '{{value, fraction}}',
  relatedStores: 'Tiendas afines',
  defaultWhatHappensNext:
    'Para ganar Karma Cash automáticamente en tu próxima compra, pulsa en uno de nuestros minoristas elegibles de Karma Cash, ¡donde puedes comprar como siempre! Recuerda: solo puedes ganar Karma Cash visitando al minorista elegible en la app de Karma o en el escritorio (incluyendo notificaciones automáticas), o interactuando con la extensión del navegador en el escritorio/móvil antes de la compra. Una vez que el minorista haya verificado tu compra, Karma confirmará tus ganancias en tu cartera de Karma.',
  defaultTermsAndConditions:
    'Karma Cash no está disponible para comprar tarjetas regalo o cualquier parte de una compra pagada con una tarjeta regalo, un vale, puntos de fidelidad u otro programa de puntos. El uso de códigos promocionales que no figuren en el sitio web de Karma puede anular el Karma Cash. Se pueden aplicar restricciones adicionales a categorías o productos específicos.',
  whatHappensNext: '¿Y luego?',
  about: 'Sobre',
  readMore: 'Leer más',
  frequentlyAskedQuestions: 'Preguntas frecuentes',
  loadMore: 'Cargar más',
  questionButton: 'Botón pregunta',
  headerKarmaCash: 'Karma | Recompensas monetarias',
  recommended: 'Recomendado',
  highToLow: 'Mayor a menor',
  lowToHigh: 'Menor a mayor',
  sortBy: 'Ordenar por:',
  exploreStores: 'Explorar tiendas',
  weHaveNotFoundStores: 'No hemos encontrado tiendas acordes con tus solicitudes',
  weHaveNotFoundCoupons: 'No hemos encontrado tiendas acordes con tus solicitudes',
  headerApps: 'Karma | Apps',
  karmaApps: 'Obtén buen karma, siempre.',
  stayUpdated:
    'Sigue al día en tus dispositivos. Guarda artículos de más de {{number, number}}k tiendas con nuestro asistente de compras digital.',
  getFaster: 'Ten una experiencia más rápida y fluida descargando Karma en tu Mac.',
  headerProfile: 'Karma | Perfil',
  headerWallet: 'Karma | Cartera',
  totalBalance: 'Saldo total',
  editYourAccount: 'Edita tu cuenta',
  emailAddress: 'Dirección email',
  enterEmailAddress: 'Introducir dirección de email',
  enterEmail: 'Introducir email',
  enterEmailLower: 'Introducir email',
  phoneNumber: 'Teléfono',
  enterPhoneNumber: 'Introducir teléfono',
  fullName: 'Nombre completo',
  enterFullName: 'Introducir nombre completo',
  enterPlaceholder: 'Introducir {{name}}',
  birthday: 'Cumpleaños',
  year: 'Año',
  month: 'Mes',
  day: 'Día',
  gender: 'Género',
  language: 'Idioma',
  currency: 'Moneda',
  multiCurrency: 'Multimoneda',
  male: 'Hombre',
  female: 'Mujer',
  notWantShare: 'No quiero compartir',
  mobilePush: 'Móvil push',
  webPush: 'Web push',
  disconnectFromPayPal: 'Desconectar de PayPal',
  loginAsUser: 'FUT',
  selectYearAndMonth: 'Selecciona primero el año y mes',
  notSureWhereToStart: '¿No sabes por dónde empezar?',
  youDidntSaveItems: 'Aún no has guardado ningún artículo.',
  searchItems: 'Buscar artículos',
  headerDashboard: 'Karma | Panel',
  headerSales: 'Karma | Ofertas',
  couldNotFindStores: 'Vaya, no hemos encontrado ofertas',
  karmaGivesValue: '{{value, number}} $',
  karmaGivesModalTitle: 'Aquí tienes {{value, number}} $ GRATIS para empezar',
  hereIsABitOfGoodKarma: '¡Aquí tienes algo de buen Karma por registrarte!',
  collectAndStartSaving: 'Recoge y ahorra ya',
  termsAndConditionsAppersant: 'Términos y condiciones',
  mayApply: 'pueden aplicarse',
  termsAndConditionsError: 'Acepta primero nuestros términos y política de privacidad.',
  validEmailError: 'Introduce un email válido.',
  completeSignUpCapital: 'Registro completo',
  or: 'O',
  password: 'Contraseña',
  joinUs: 'Únete',
  reviewsPlus: '+{{amount, number}}k reseñas',
  logIn: 'Iniciar sesión',
  termsAgreement: 'Al registrarte, aceptas nuestros <1>Términos</1> y <3>Política de privacidad</3>',
  termsAgreementWithCheckbox: 'Acepto los <1>Términos y condiciones</1> y <3>Política de privacidad</3>',
  createANewList: 'Crear nueva lista',
  listCreateBlickError: 'Introduce un nombre de lista',
  enterListName: 'Introducir nombre de lista',
  listDescriptionOptional: 'Descripción de lista (Opcional)',
  listName: 'Nombre de la lista',
  enterDescriptionForList: 'Introducir descripción de la lista',
  save: 'Guardar',
  deleteList: 'Eliminar lista',
  areYouSureRemoveList: '¿Seguro de querer eliminar esta lista?',
  editList: 'Editar lista',
  listNameCanNotBeBlanck: 'Introduce un nombre de lista',
  shareList: 'Compartir lista',
  notAMemberCreateAccount: '¿No eres miembro? <1>¡Regístrate!</1>',
  backToLogin: 'Volver para acceder',
  enterYourEmail: 'Introducir dirección de email',
  resetPassword: 'Restablecer contraseña',
  forgotPasswordMessage: 'Recibirás un email con instrucciones para restablecer tu contraseña en unos minutos.',
  forgotPassword: '¿Contraseña olvidada?',
  reCaptchaMessage:
    'Este sitio está protegido por reCAPTCHA y aplican la <1>Política de privacidad y las <3>Condiciones de servicio',
  pleaseScan: 'Escanea',
  saveAnItem: 'Guardar un artículo',
  areYouSureFulfilled: '¿Seguro que quieres marcar como ejecutados estos productos?',
  muteTagDesc: '¿Seguro que quieres silenciar las notificaciones para estos productos?',
  muteTagTitle: 'Silenciar notificaciones',
  ok: 'OK',
  canAccessEarningsInPayPal: 'Puedes acceder a tus ganancias en tu cuenta PayPal',
  ccpaTitle:
    'Ley de Privacidad del Consumidor de California (CCPA), modificada por la Ley de Derechos de Privacidad de California (CPRA)',
  ccpaText: ' Al enviar este formulario, ejerzo mi derecho a rechazar cualquier venta de mi información personal.',
  submit: 'Enviar',
  joinKarmaAndGetRewards: 'Únete y obtén {{stake, number}} recompensas en {{name}}',
  doNotMissOut: 'No te lo pierdas. Puedes ahorrar en tu artículo.',
  signUpAndGetMoney: 'Regístrate y obtén {{value, number}} $',
  itemAlert: '¡Tienes una alerta de artículo!',
  accessKarmaCash: 'Accede a Karma Cash',
  youEarnedKarmaCash: '¡Acabas de ganar Karma Cash!',
  verifyYourPaypalAccount: 'Verifica tu cuenta<1/>Paypal',
  weHaveSentAnEmailTo: 'Hemos enviado un email a',
  'Fall Favorites': 'Favoritos de otoño',
  'Karma Cash': 'Karma Cash',
  'Clothing & Luxury': 'Moda y lujo',
  'Home Decor & Furniture': 'Decoración y muebles de hogar',
  'Electronics & Office Supplies': 'Electrónica y material de oficina',
  Luxury: 'Lujo',
  Clothing: 'Moda ',
  'Handbags & Shoes': 'Bolsos y calzado',
  'Jewelry & Watches': 'Joyería y relojería',
  Accessories: 'Accesorios',
  'Beauty Essentials': 'Básicos de belleza',
  'Health & Wellness': 'Salud y bienestar',
  Lifestyle: 'Estilo',
  'Sports & Outdoors': 'Deporte y exterior',
  Travel: 'Viajes',
  'Electronics & Office supplies': 'Electrónica y suministros de oficina',
  'Entertainment & Online Services': 'Entretenimiento y servicios online',
  Education: 'Educación',
  'Finance & FinTech': 'Finanzas y fintech',
  'Pet Supplies': 'Suministros para mascotas',
  'Food & Beverages': 'Alimentación y bebidas',
  Men: 'Hombre',
  Women: 'Mujer',
  Unisex: 'Unisex',
  Kids: 'Niños',
  Baby: 'Bebé',
  notifications: 'Notificaciones',
  changesSaved: '¡Cambios guardados!',
  headerRetailer: 'Karma | Minorista',
  headerRedirect: 'Karma | Redirigiendo...',
  karma: 'Karma',
  goBackToKarma: 'Volver a Karma',
  authentication: 'Autenticación',
  authSuccess: '¡Enhorabuena! Acabas de iniciar sesión correctamente.',
  authFailure: 'Vaya, no hemos podido autorizarte. Inténtalo más tarde.',
  mayNotQualify: 'Algunos productos pueden ser excluidos de Karma Cash',
  checkOutRetailers: 'Consulta nuestros minoristas asociados a Karma Cash.',
  headerHowItWorks: 'Karma | Cómo funciona',
  accept: 'Aceptar',
  headerError: 'Karma | Error',
  pageNotFound: 'Página no encontrada',
  pageDoesNotExist: 'Vaya, parece que esta página no existe.',
  backHome: 'Volver a Inicio',
  startShopping: 'Compra ya',
  illustration: 'Ilustración',
  dashboard: 'Panel',
  allRightsReserved: 'Todos los derechos reservados.',
  product: 'Producto',
  disclaimer:
    'Karma Shopping Ltd., como Karma. Karma no posee, ni pretende poseer, la propiedad de marcas comerciales de terceros. Las marcas de terceros visibles o accesibles directa o indirectamente a través de la plataforma de Karma, no tienen necesariamente una relación con Karma o terceros asociados a Karma o proveedores de servicios de Karma.',
  help: 'Ayuda',
  team: 'Equipo',
  howItWorks: 'Cómo funciona',
  supportedStores: 'Tiendas asociadas',
  company: 'Empresa',
  planYourPurchase: '¡Planifica tu próxima compra con nuestra aplicación gratuita!',
  itSitsInBrowser: 'Se sitúa en tu navegador web mientras navegas por todas tus tiendas favoritas.',
  seeSmthYouLike:
    '¿Ves algo que te gusta? Haz clic en el botón para guardarlo en tu lista y Karma se encargará de supervisar su precio y disponibilidad.',
  youWillGetNotified:
    'Recibirás una notificación instantánea cuando alguno de los artículos que has guardado esté en promoción, rebajado o disponible de nuevo en tu talla y color preferidos.',
  everythingYouWant:
    'Tus Favoritos estarán en un solo lugar, para que puedas organizarte y dejar de hacer malabares con varias listas de deseos.',
  headerContactUs: 'Karma | Contáctanos',
  needToGetInTouch: '¿Necesitas ponerte en contacto?',
  dropALine: 'Escríbenos un mensaje',
  comeHangOut: 'Ven a divertirte',
  joinOurFacebookCommunity: 'Síguenos en Facebook',
  followOnInstagram: 'Síguenos en Instagram',
  headerAboutUs: 'Karma | Quiénes somos',
  addTheItems:
    'Añade los artículos que quieres comprar. Puedes personalizar hasta la talla y el color, y la aplicación te avisará cuando estén en promoción.',
  jonathanSay:
    'Jonathan: Los minoristas llevan años aprovechando la IA, pero ahora es el consumidor quien usará las herramientas de aprendizaje automático y automatización para ganar eficiencia.',
  justDownload:
    'Descarga el botón y púlsalo cuando llegues a un artículo que te guste para guardarlo en tu lista. En el móvil, puedes compartir artículos directamente a las mismas listas.',
  ifYouFind:
    'Si encuentras un artículo que te gusta en Internet pero que se sale de tu presupuesto, puedes configurar una alerta de promoción para saber si baja de precio.',
  onlyAHandful: 'Quedan pocas empresas que ofrecen alertas de promoción para los miembros online, entre ellas, Karma.',
  alwaysBuy: 'Compra siempre algo en promoción con Karma.',
  headerCareers: 'Karma | Carreras',
  getInTouch: 'Contacta con nosotros:',
  shop: 'Tienda',
  weCouldNotFindResults: 'No encontramos resultados para «{{name}}»',
  couldNotFindResults: 'No encontramos ningún resultado',
  shopAllStores: 'Comprar en tiendas asociadas',
  popularStores: 'Tiendas populares',
  shops: 'Tiendas',
  headerDiscoverAndShop: 'Karma | Descubre y compra los últimos artículos guardados',
  prev: 'Prev.',
  next: 'Siguiente',
  headerDemo: 'Karma | Demostración',
  getTheKarmaButton: 'Obtén el botón Karma',
  installedKarma: '¡Has instalado Karma correctamente!',
  settingUpAccount: 'Ahora, empecemos configurando tu cuenta.',
  signUpNow: 'Regístrate',
  logInNow: 'Inicia sesión ahora',
  signUpLater: 'Regístrate más tarde',
  wePartnerWith: 'Colaboramos con +{{number, number}}k de tiendas online.',
  pickStoresToSee: 'Escoge una de las tiendas de abajo para ver cómo funciona:',
  leaveKarma: 'Debes salir de Karma e ir a la página de la tienda para obtener la experiencia completa.',
  searchFavoriteStores: 'Busca tus tiendas favoritas',
  clickBelowToStart: 'Pulsa abajo para empezar',
  chooseOneOfTheOptions: 'Elija una opción a continuación',
  clickToBegin: 'Pulsa la barra de búsqueda para empezar',
  preferNotToSay: 'Prefiero no decirlo',
  done: 'Hecho',
  headerUnsubscribe: 'Karma | Anular suscripción',
  changeMind: '¿Cambiaste de opinión?',
  resubscribeList: 'Reinscríbete en tu lista.',
  resubscribeList_plural: 'Reinscríbete en tu lista aquí.',
  changePreferencesAtAnyTime:
    'También puedes cambiar tus preferencias en cualquier momento en<1>ajustes de la cuenta.</1>',
  preferencesUpdated: 'Hemos actualizado tus preferencias de email.',
  sorryToSeeYouGo: '¡Sentimos que te vayas!',
  reSubscribed: 'Te has resuscrito',
  allowHours: 'Puede llevar hasta {{hours, number}} horas para que los cambios surtan efecto.',
  missWhenYouGone:
    '¡Te echaremos de menos! Puede llevar hasta {{hours, number}} horas para que los cambios surtan efecto.',
  headerDisablingSubscribe: 'Karma | Desactivar suscripción',
  noLongerAlerts: 'Ya no recibirás alertas para este artículo.',
  turnAlertsBack: 'Reactivar las alertas.',
  moveAnimations: 'Mover animación del botón',
  headerExtensionDeleted: 'Karma | Extensión eliminada',
  areSorryToSeeYouGo: '¡Sentimos que te vayas!',
  youHaveGotInYourWallet: 'Tienes {{value}} $ en tu Cartera.',
  toWithdraw: ' para retirar tus ganancias.',
  toContinue: ' para seguir ganando recompensas.',
  reasonUninstalled: 'Por favor, dinos por qué has desinstalado:',
  getInTouchBtn: 'Ponte en contacto',
  howWeCanIncrease: 'Así puedes aumentar tu visibilidad e impulsar las ventas anunciándote con Karma',
  helpingShoppers:
    'Ayudar a los compradores a descubrir marcas que les gustarán es una de nuestras mayores prioridades como asistente personal de compras.',
  whyWeOfferBrands:
    'Por eso ofrecemos a las marcas que ofrecen una alta satisfacción al cliente diversas formas de conectar con nuestro público. Tú logras tus objetivos de campaña y nuestro público conoce su nueva marca favorita.',
  winWin:
    'Ofrecemos a las marcas que ofrecen una alta satisfacción al cliente diversas formas de conectar con nuestro público. Tú logras tus objetivos de campaña y nuestro público conoce su nueva marca favorita, ¡todos ganan!',
  getFeaturedRight: 'Aparece en el panel de nuestra app y extensión del navegador',
  getFeaturedDashboard: 'Aparece en el panel de Karma',
  putYourHand:
    'Sitúa tus habilidades, productos o promociones en el lugar exacto al que acuden nuestros usuarios cuando se disponen a comprar. Podemos crear una variedad de contenidos de marca personalizados que impulsen el tráfico caliente allí donde lo necesites.',
  exactPlace: 'Sitúa tu marca en el lugar exacto donde acuden nuestros usuarios cuando quieren comprar.',
  dedicatedInAppPlacement: 'Posicionamiento exclusivo en la app',
  dashboardFeaturedBanners: 'Banners destacados en el panel de control',
  dashboardPopUpMessages: 'Mensajes push del panel',
  customEmailsAndNewsLetters:
    'Emails personalizados y posicionamiento de boletines a +{{subscribers, number}} suscriptores',
  customEmails: 'Emails personalizados a +{{subscribers, number}} suscriptores',
  getMoreEyes:
    'Atrae más miradas hacia tu última oferta, código de cupón o línea de productos con un email o boletín para compradores.',
  putYourBrand: 'Sitúa tu marca en el lugar exacto al que acuden nuestros usuarios cuando quieren comprar.',
  newsletterInclusion: 'Inclusión en boletín',
  dedicatedCustomEmails: '{{percent, number}} % emails personalizados exclusivos',
  bannerPlacementInEmails: 'Posicionamiento de banners en emails de notificación',
  pushNotificationsToShoppers: 'Notificaciones push a +{{shoppers, number}} compradores',
  pushNotificationsGiveTouchPoint:
    'Las notificaciones push aportan a tu campaña otro tipo de contacto y ofrecen altos índices de clics y apertura.',
  featureBrandClients: 'Clientes de marca destacados',
  growingPerMonth: 'Crecemos +{{number, number}}k al mes',
  members: 'Miembros',
  percent: '{{value, number}} $',
  kindOfReachYouExpect: '¿Qué alcance se puede esperar?',
  ourAudience: 'Nuestro público',
  tabIntoNewSource: 'Aprovecha una nueva fuente de clientes cualificados para tu próxima campaña',
  tellUsAboutYourCompany:
    'Háblanos de tu empresa y de tus objetivos y te indicaremos cómo podemos ayudarte a superarlos.',
  openAndTap: 'Abre Safari y ve a la página del producto que quieres guardar y toca el botón «Compartir».',
  shareIllustration: 'Compartir ilustración',
  tapMore: 'Pulse el botón «Más» a la derecha del panel.',
  moreIllustration: 'Más ilustración',
  enableIllustration: 'Habilitar ilustración',
  tapEdit: 'Pulse el botón «Editar» en la parte superior del panel.',
  enableApp: 'Habilita Karma y añade el botón verde «Añadir» para añadirlo a tus Favoritos y luego toca «Hecho».',
  goBackToTheProduct:
    'Vuelve a la página del producto, toca de nuevo el botón «Compartir» y luego toca el botón «Karma» para guardar tu producto.',
  recommendedBy: 'Recomendado por',
  worldIsChanging:
    'El mundo cambia. Únete a más de {{number, number}} millones de miembros, compra con inteligencia y ahorra.',
  ourMembersSavingMoney: 'Nuestros miembros ❤️ ahorran con Karma. Descubre por qué.',
  addToBrowser: 'Añádelo a {{browser}}, es gratis',
  planPurchases: 'Planifica tus próximas compras',
  karmaMakesItEasy: 'Karma te pone fácil organizar tus próximas compras en un solo lugar.',
  buyAtRightTime: 'Compra en el mejor momento',
  automaticallyAlert: 'Karma te avisa automáticamente cuando es el mejor momento de comprar.',
  cashOnVacation: 'Karma Cash en tus próximas vacaciones',
  karmaPartners: 'Karma colabora con webs de viajes y hoteles.',
  headerRewards: 'Karma | Mejores cupones y recompensas monetarias',
  mission: 'Nuestra misión',
  empowerYou: 'Permitirte tomar las decisiones correctas en el mejor momento cuando compras online.',
  weAreSeriousAboutPrivacy: 'Nos importa mucho tu privacidad y seguridad',
  weDoNotSellData: 'No vendemos tus datos',
  karmaIsSerious: 'Karma toma muy en serio tus datos y nunca los venderemos a terceros. Nunca.',
  karmaIsSecure: 'Karma es seguro',
  weUseBankSecurity:
    'Usamos encriptación de {{bit, number}} bits de seguridad bancaria para garantizar la seguridad de tu información.',
  helpsSaveMore: '¡Con Karma ahorras más!',
  alertMe: 'Avísame',
  buyWithKarmaEarn: 'Compra con Karma, gana {{number, number}} $',
  productSaved:
    '¡Enhorabuena! Este producto se ha guardado en tu lista de artículos. Te avisaremos cuando el precio baje.',
  willApplyTheBestCoupons: '¡Karma aplicará automáticamente los mejores cupones a tu compra!',
  downloadAndAutoApply: 'Descarga Karma y ahorra',
  whatIsKarma: '¿Qué es Karma?',
  claimUpToRewards: 'Solicita hasta un {{number, number}} % en recompensas',
  youMightAlsoLike: 'También te puede interesar',
  weAreSorry: 'Se ha producido un error y no se puede mostrar la página.',
  tryToReloadPage: 'Prueba a recargar la página.',
  Color: 'Color',
  Size: 'Tamaño',
  latestCoupons: '{{name}} | Últimos cupones y reembolsos Karma {{year, clearNumber}}',
  neverPayFullPrice: 'Obtén descuentos con los cupones y reembolsos {{name}} de Karma',
  neverPayFullPriceForTag: 'Obtén descuentos en {{name}}',
  saveMoneyWhenShoppingAt: 'Ahorra dinero al comprar en {{name}}. Únete a Karma gratis',
  saveMoneyWhenShoppingFor: 'Ahorra dinero al comprar en {{name}}. Únete a Karma gratis',
  tagByBrand: 'Karma | {{tag}} de {{brand}}',
  typeToSearch: 'Escribe para buscar',
  getUSDWhenJoinToday: '¡Obtén {{value, number}} $ por unirte a Karma hoy!',
  buyProduct: 'Comprar producto',
  userWishList: 'Karma | Lista {{name}}',
  byWhom: 'por',
  copiedToClipboard: '¡Copiado al portapapeles!',
  send: 'Enviar',
  cause: 'Seleccione la causa:',
  aCause: 'Seleccione una causa:',
  close: 'Cerrar',
  selectThisCause: 'Seleccionar esta causa',
  habitatForHumanity:
    '{{name}} lucha contra la pobreza a largo plazo a través de unas viviendas sociales asequibles. Su misión es desarrollar un entorno seguro donde familias y comunidades prosperen',
  casaDescription:
    'La Asociación Nacional CASA/GAL promueve la asertividad de los voluntarios designados por el tribunal para que cada niño que haya sufrido abuso o negligencia esté seguro, en un hogar permanente y con la oportunidad de prosperar',
  endHomelessnessDescription:
    'La Alianza Nacional para {{name}} es una organización no partidista comprometida con la prevención y el fin de la falta de vivienda en Estados Unidos',
  pathDescription: 'PATH está acabando con la falta de vivienda de las personas, familias y comunidades',
  feedingAmericaDescription: 'Feeding America es la mayor organización nacional de ayuda contra el hambre',
  mealsOnWheelsDescription:
    '{{name}} empodera los programas comunitarios locales para mejorar la salud y la calidad de vida de las personas mayores para que no pasen hambre ni estén aislados',
  actionAgainstHunger:
    '{{name}} es una organización humanitaria mundial que actúa con decisión contra las causas y los efectos del hambre',
  supportMedicalAdvancement: 'Apoyo al avance médico',
  saveChildsHeartDescription:
    "Save A Child's Heart cura los corazones sin importar la raza, la religión, el género, la nacionalidad ni la situación económica",
  su2c: 'Stand Up To Cancer (SU2C) financia y desarrolla los tratamientos contra el cáncer más novedosos y prometedores',
  drWithoutBorders: '{{name}} es un movimiento independiente y global que ofrece ayuda médica donde más se necesita',
  activeMinds:
    '{{name}} es la principal organización sin ánimo de lucro del país que apoya la concienciación y la educación psicológicas de los jóvenes adultos',
  cleanAirTaskForceDesc:
    '{{name}} impulsa el cambio de tecnologías y políticas necesario para llegar a un planeta con cero emisiones y alta energía a un precio asequible',
  wwfDesc:
    'La misión de {{name}} es preservar la naturaleza y reducir las amenazas más acuciantes para la diversidad de la vida en la Tierra',
  waterkeepsDesc:
    '{{name}} aboga por un agua limpia. Waterkeeper Alliance trabaja para garantizar que todas las comunidades del mundo tengan agua potable, pescable y nadable',
  xprice:
    'XPrize trabaja por un mundo mejor en el que todos tengan acceso a agua limpia, alimentos nutritivos, viviendas asequibles, enseñanza eficaz, atención médica de primer nivel y energía abundante y no contaminante',
  globalActDesc: '{{name}} trabaja para poner fin a la explotación sexual y comercial',
  pcaa: '{{name}} trabaja con ahínco para que todos los niños crezcan felices, sanos y preparados para triunfar con sus familias y unas comunidades solidarias',
  endCAN:
    'EndCAN sensibiliza sobre las repercusiones del maltrato en la salud, la salud mental y la salud pública, con el fin de acabar con esta lacra y el abandono de los niños',
  futuresDesc:
    'FUTURES ofrece programas, políticas y campañas innovadoras que forman a las personas y organizaciones que trabajan para poner fin a la violencia contra las mujeres y los niños en todo el mundo',
  thornDesc: '{{name} diseña tecnología para defender a los niños de los abusos sexuales.',
  'Eradicate Homelessness': 'Dar vivienda a los sin techo',
  'Help End Hunger': 'Ayudar a acabar con las hambrunas',
  'Support Medical Advancement': 'Apoyar los avances médicos',
  'Combat Climate Change': 'Luchar contra el cambio climático',
  'Act To End Abuse': 'Actuar para acabar con los abusos ',
  itemsYouSaved: 'Artículos que guardó para ahorrar',
  beta: 'BETA',
  alsoAvailableAt: 'También disponible en',
  atName: 'en {{name}}',
  bestPrice: 'Mejor precio',
  passwordWasSuccessfullyReset: 'La contraseña se ha restablecido con éxito',
  paidWith: 'Pagado con',
  infoFirstNumber: '{{number, number}}{{unit}}+',
  infoFirstTitle: 'Miembros',
  infoSecondNumber: '{{number, number}}{{unit}}+',
  infoSecondTitle: 'Visitas mensuales',
  infoThirdNumber: '{{number, number}}{{unit}}+',
  infoThirdTitle: 'Lista de correo electrónico',
  infoFourthNumber: '{{value, number}}%',
  infoFourthTitle: 'De millenials',
  infoFifthNumber: '{{value, number}}%',
  infoFifthTitle: 'De mujeres',
  infoSixthNumber: '{{value, number}}%',
  infoSixthTitle: 'En EE. UU.',
  infoSeventhNumber: '{{number, number}}{{unit}}+',
  infoSeventhTitle: 'Altas en notificaciones push móviles',
  infoEighthNumber: '{{number, number}}{{unit}}+',
  infoNinthPreNumber: '',
  infoNinthNumber: '${{number, number}}{{unit}}+',
  infoNinthTitle: 'Gastarán los usuarios de aquí a finales de {{year, clearNumber}}',
  karmaIsAlwaysWithYou: 'Karma está siempre con usted cuando compra',
  saveItemsToBuy: 'Guarde sus artículos y le diremos cuándo comprar',
  weDoTheWork: 'Encontramos los mejores cupones por usted',
  earnRealCashThatAdds: 'Gane dinero real que se acumula en su cartera',
  setUpStrongPassword: 'Establezca una contraseña segura',
  settingEmailAccount:
    '¿El último paso para ahorrar dinero con Karma? Regístrese y recibirá alertas importantes sobre sus artículos, como las bajadas de precio.',
  settingPasswordAccount:
    'La contraseña debe estar formada por un mínimo de {{number, number}} caracteres y no debe incluir sus credenciales de correo electrónico.',
  countWaysToUse: '{{number, number}} Formas de usar Karma',
  goBack: 'Volver',
  pleaseChooseStrongerPassword: 'Escoge una contraseña más segura',
  pleaseEnterValidEmailAddress: 'Introduce una dirección de correo electrónico válida',
  illSignUpLater: 'Me registraré más tarde',
  enterYourEmailAddress:
    'Introduce tu dirección de correo electrónico y te enviaremos un enlace para restablecer tu contraseña.',
  headerOnboardingIosExtension: 'Karma | Extensión IOS de incorporación',
  weWillAutomaticallyApplyCouponsWhereverYouShop: 'Aplicaremos automáticamente los cupones en todas tus compras',
  continue: 'Continuar',
  changeLanguage: 'Cambiar idioma',
  selectedLanguage: 'Idioma seleccionado',
  headerMuteItem: 'Karma | Silencia artículo',
  itemHasBeenMuted: '¡Hecho! Se ha silenciado este artículo.',
  toUnmuteItem: 'Para reactivar este artículo:',
  headToMyItems: "Entra en la pestaña 'Mis artículos'",
  clickThreeButtonsInItem: 'Pulsa los tres botones en la esquina inferior derecha del artículo',
  clickUnmute: "Pulsa 'Reactivar'",
  weveJustRaisedInFunding:
    '¡Hemos recaudado {{value, number}} $ M en financiación de serie A para ayudarte a comprar mejor!',
  weveJustRaised: 'Hemos recaudado {{value, number}} $ M',
  landingReview1: '¡Gran aplicación! ¡Recibí una alerta de descuento y ahorré mucho dinero!',
  landingReview2: 'Ahorré {{value, number}} $ en mi primera compra. ¡Muy impresionado!',
  landingReview3: 'Es una aplicación beneficiosa; amo las notificaciones y recompensas.',
  landingReview4: '¡La experiencia de compra más fluida que he vivido!',
  landingReview5: '¡Ahorré un {{value, number}} %! ¡No conocería la oferta de otro modo!',
  landingReview6: 'Es fácil seguir tus productos favoritos... ¡Muy recomendable!',
  landingReviewAuthor1: 'Justin Nguyen',
  landingReviewAuthor2: 'Amy Ellis',
  landingReviewAuthor3: 'Maude Aethelredd',
  landingReviewAuthor4: 'Patrick Slocum',
  landingReviewAuthor5: 'Michelle Findley',
  landingReviewAuthor6: 'Varsha Hulekal',
  auxiliaryDate: '{{date}}',
  resetMyPassword: 'Restablecer mi contraseña',
  repeatPassword: 'Repetir contraseña',
  continueAsGuest: 'Continuar como invitado',
  viewAt: 'Ver en {{- name}}',
  signUp: 'Regístrate',
  youMayAlsoLike: 'También te puede gustar',
  buyOnShop: 'Comprar en',
  viewOn: 'Ver en {{- name}}',
  quickView: 'Vista rápida',
  newList: 'Nueva lista',
  undo: 'Deshacer',
  productDeleted: 'Producto eliminado',
  linkCopiedToClipboard: 'enlace copiado al portapapeles',
  wishlists: 'Listas de deseos',
  headerPartnerships: 'Karma | Asociaciones',
  letsJoinForces: 'Unamos fuerzas',
  bePartOfTheSmartShoppingRevolution:
    'Participa en la revolución de las compras inteligentes. Conseguiremos que tu marca llegue a más de {{number, number}} millones de compradores listos para pasar por caja.',
  seeKarmaInAction: 'Mira Karma en acción',
  discoverHowKarma:
    'Descubre cómo las soluciones basadas en IA y la automatización de Karma mejoran el proceso de compra, dirigiendo el tráfico hacia tus ofertas.',
  stepUpYourSales: 'Incrementa tus ventas',
  averageROAS: 'ROAS<br/>promedio',
  increaseInAOV: 'Aumento<br/>del AOV',
  useKarmaToPlanTheirNextPurchase: 'Utiliza Karma para planificar<br/>su próxima compra',
  meetOurShoppers: 'Conoce a nuestros compradores',
  meetOurShoppersDescription:
    'Aprovecha nuestras soluciones de marketing basadas en el rendimiento y deja huella en los compradores con alta intención de compra. Con nuestros posicionamientos multiplataforma llegarás a los usuarios estén donde estén.',
  audienceInfoFirstNumber: '+{{number, number}}{{unit}}',
  audienceInfoFirstTitle: 'Usuarios',
  audienceInfoSecondNumber: '+{{number, number}}{{unit}}',
  audienceInfoSecondTitle: 'Visitas mensuales',
  audienceInfoThirdNumber: '+{{number, number}}{{unit}}',
  audienceInfoThirdTitle: 'Artículos guardados',
  audienceInfoFourthNumber: '{{value, number}} %',
  audienceInfoFourthTitle: 'Usuarios de EE. UU.',
  audienceInfoFifthNumber: '{{value, number}} %',
  audienceInfoFifthTitle: 'Generación Z y millennials',
  audienceInfoSixthNumber: '{{value, number}} $',
  audienceInfoSixthTitle: 'Mujeres compradoras',
  ourUsersLoveUs: '¡Nuestros usuarios nos adoran!',
  partnershipsReview1:
    '¡Una gran extensión! Hace años que la utilizo y es genial poder guardar muchos productos en un solo lugar: ¡así las compras por Internet son mucho menos estresantes!',
  partnershipsReviewAuthor1: 'Millie Robinson',
  partnershipsReview2:
    'Como persona que compra MUCHO por Internet, puedo decir que Karma es el mejor amigo de los compradores por muchas razones...',
  partnershipsReviewAuthor2: 'Sadie Mae',
  partnershipsReview3:
    'Mejor aplicación de compras en Play Store. Muy fácil de usar. ¡Tengo todos mis artículos guardados en mis tiendas favoritas en una sola aplicación! 👍🏻',
  partnershipsReviewAuthor3: 'Kimberly Rogers',
  performanceBasis: 'Base del rendimiento',
  fixedFee: 'Tarifa fija',
  thanksForSubmitting: '¡Gracias por enviarlo!',
  someoneFromOurPartnershipsTeamWillBeInTouchShortly: 'Un miembro de nuestro equipo de socios te contactará pronto.',
  letsKickStartOurPartnership: '¡Iniciemos nuestra asociación!',
  emailOrPhoneNumber: 'Email o número de teléfono',
  retailerName: 'Nombre del vendedor',
  affiliateNetwork: 'Red de afiliados',
  budgetStructure: 'Estructura presupuestaria',
  budget: 'Presupuesto',
  tellUsMore: 'Cuéntanos más',
  pleaseFillInAllRequiredFields: 'Rellena todos los campos obligatorios',
  partnerships: 'Asociaciones',
  weCannotSaveThisItem: 'No podemos guardar este elemento <1/> porque no se soporta esta tienda',
  theresCoupon: '¡Hay un cupón!',
  yourItemIsOffWithCode: 'Su elemento al {{value, number}}% con el código {{code}} en {{- name}}.',
  muteAllNotification: 'Silenciar las notificaciones',
  unMuteAllNotification: 'Desilenciar todas notificaciones',
  notificationSettings: 'Ajustes notificación',
  deleteNotification: 'Eliminar notificación',
  notificationDeleted: 'Notificación eliminada',
  itemMuted: 'Elemento silenciado',
  itemUnMuted: 'Elemento desilenciado',
  priceDrop: 'Precio bajado',
  superDeal: '🔥 Superoferta',
  notSureWhenToBuy: '¿No sabe cuándo comprar? Crear una cuenta gratis y te diremos cuando hay oferta.',
  tcMayApply: 'Condiciones pueden aplicarse',
  earnKarmaCash: 'Gana hasta {{percent, number}} % de Karma Cash.',
  saveToKarma: 'Guardar artículo en Karma',
  getKarmaToTrackItem: 'Obtén Karma para rastrear artículo',
  itemSaved: '¡Artículo guardado!',
  youCanNowFindItem: 'Ahora encontrarás tu artículo en tu panel de Karma.',
  getKarmaExtension: 'Obtén la extensión Karma para <1/> rastrear este imprescindible',
  notifyYou: '¡Te avisaremos cuando sea el mejor momento para comprar!',
  downloadTheExtension: 'Descargar la extensión',
  outOfStock: 'Sin existencias',
  invalidEmailError: 'E-mail inválido',
  collapse: 'Contraer',
  expand: 'Expandir',
  lists: 'Listas',
  organizeYourItemsIntoWishListsWithJustAClick: 'Organiza tus artículos en listas de deseos con un clic.',
  newItem: 'Nuevo artículo',
  helpCenter: 'Centro ayuda',
  priceHighToLow: 'Precio, mayor a menor',
  priceLowToHigh: 'Precio, menor a mayor',
  sale: 'Oferta',
  filterBy: 'Filtrar por:',
  karmaExclusive: 'Exclusivo K',
  noItemsFound: 'Ningún artículo encontrado',
  select: 'Selecciona',
  likeSneakersOrBlackFriday: 'Como "Zapatillas" o "Black Friday"',
  updateList: 'Actualizar lista',
  firstName: 'Nombre',
  lastName: 'Apellidos',
  enterFirstName: 'Introduce el nombre',
  enterLastName: 'Introduce los apellidos',
  recentSearches: 'Búsquedas recientes',
  addYourFavoriteStores: 'Añade tus tiendas favoritas',
  toAccessTheirLatestCoupons: 'para conseguir sus últimos cupones',
  logosProvidedByClearbit: 'Logotipos facilitados por Clearbit',
  dateOfBirth: 'Fecha de nacimiento',
  weHaveEmailedYouALinkToResetYourPassword: 'Te enviamos un enlace por e-mail para restablecer tu contraseña.',
  didNotGetIt: '¿No lo recibiste?',
  profile: 'Perfil',
  promotionsAndActivity: 'Promociones y actividad',
  couponsForFavoriteStores: 'Cupones para tiendas favoritas',
  accountActivity: 'Actividad cuenta',
  powerShopper: '¿Supercomprador? 💪',
  enterLinkToAnyItemPage: 'Introduce un enlace a cualquier página artículo',
  saveStuffWithZeroFuss: '<0>Obtén la extensión del navegador </0> y guarda artículos sin complicaciones.',
  viewMore: 'Ver más',
  tooHotToMiss: 'Demasiado buena para perdértela',

  // WebsiteLandingKarmaOld
  joinShoppers: '{{number, number}} millones de compradores, únete',
  waysToUse: 'Maneras de usar Karma',
  youCanUseKarma:
    'Puedes utilizar Karma de muchas maneras, como asegurarte de que obtienes el mejor precio en el mejor momento, además de planificar tus próximas compras. Aquí tienes algunos ejemplos que recomendamos:',
  livesInBrowser: 'Karma vive en tu navegador',
  browserExtension: 'El botón de Karma aparecerá en cualquier página web que estés comprando.',
  helpsYouPlan: 'Te ayuda a planificar tus compras',
  notReadyToBuyJust:
    '¿Aún no estás seguro de comprar? Guarda el artículo con la extensión de Karma para después y compra de forma más consciente. Podrás controlar todos tus artículos guardados en un mismo lugar. ',
  automaticallyFindsCouponCodes: 'Encuentra automáticamente códigos promocionales',
  findsAndAppliesCodes:
    'Karma encuentra y aplica automáticamente el mejor cupón para ti, para que sepas que obtienes la mejor relación calidad-precio siempre.',
  soYouCanBuyAtTheRightTime: 'Para que compres en el mejor momento',
  usesCleverTechnologies:
    'Karma usa tecnología inteligente para saber si el precio baja, o cuándo vuelve a estar disponible, para que puedas comprar en el mejor momento. ',
  notOnlySave:
    'Además de ahorrarte dinero, Karma te hace ganar dinero. Sin puntos. Sin trucos. Ganas dinero real cada vez que compras.',
  makeImpact: '¡Deja tu huella!',
  donate:
    'Compra regularmente y Karma te recompensará. Por cada compra que supere los {{over, number}} $, donamos {{dollars, number}} $ a la causa que elijas.',
  accessToOffers: 'Accede a ofertas exclusivas',
  accessItems:
    'Con Karma, podrás acceder a tus artículos almacenados en un mismo lugar, descubrirás ofertas exclusivas y las más populares a nivel mundial',
  hereIsHowItWorks: 'Así funciona',
  learnMoreAboutGives: 'Descubre más sobre Karma Gives',
  alwaysKnowWhenPricesDrop: 'Entérate cuando bajen los precios',
  getNotifiedWhenBackInStock: 'Te avisamos cuando esté en stock',
  findsYouBestCoupons: 'Te da los mejores cupones',
  getKarmaCashWhenYouShop: 'Consigue Karma Cash al comprar',
  easilySaveAllThings: 'Ahorra fácilmente en tus artículos favoritos en más de 10.000 tiendas',
  karmaProvidesGreatTechnology:
    'Karma te aporta gran tecnología y flexibilidad para comprar mejor, ayudándote a evitar las compras impulsivas innecesarias y ahorrándote tiempo y dinero.',

  accountActivityAndImportantInformation: 'Actividad de la cuenta e información importante',
  addKarmaToYourDesktop: 'Añade Karma a tu escritorio para comprar<br/> en el horario de trabajo.',
  addNumberItemsToList: 'Añadir {{value}} artículos a la lista',
  addOneItemToList: 'Añadir 1 artículo a la lista',
  addStuffYouFindToThisListToKeepItOrganized: 'Añade cosas que encuentres a esta lista para tenerla organizada.',
  alrightBut: 'Muy bien, ¡pero lamentarás perderte grandes ofertas!',
  areYouSureYouWantToDisconnectFromPaypal: '¿Seguro que quieres desconectarte de Paypal?',
  balance: 'Saldo',
  buyWhateverYouWant: 'Compra lo que quieras y mantén contento al banquero.',
  cashIsBack: '<1>El dinero</1> está de vuelta',
  cashIsBackColoredRight: 'El dinero está <1>de vuelta</1>',
  cashOutRewards: 'Cobra dinero de recompensas',
  checkItOffAsYouBuy: 'Revísala mientras compras',
  congratulationsOnCashout: '¡Felicidades! Ya puedes retirar tus recompensas.',
  connectToPayPalToCashOut: 'Conecta una cuenta PayPal para retirar tus recompensas',
  connectYourPaypalAccountToCashOutRewards: 'Conecta tu cuenta PayPal para retirar tus recompensas.',
  downloadOurApp: 'Descarga nuestra aplicación y llévate <1>Karma siempre contigo</1>',
  dropSomeShoppingInspo: 'Deja que tu familia reciba inspiración para comprar.',
  earnKarmaCashShort: 'Hasta {{percent, number}}% en K Cash',
  earned: 'Ganado',
  enterYourPassword: 'Introduce tu contraseña',
  firstAddToList: 'Primero, añadir a la lista',
  followOnLinkedin: 'Síguenos en LinkedIn',
  freshOutCoupons: 'Cupones recién agotados',
  getBuzzedWhenYourWishes: 'Enloquece 🐝 cuando tus "deseos" bajen de precio o vuelvan a estar disponibles.',
  getIt: 'Consíguelo',
  getTheApp: 'Obtener la aplicación',
  gotIt: 'Entendido',
  gotStuck: '¿Te has bloqueado? Haz clic <1>aquí</1> para arrancar.',
  happyShoppers: '👉 Más de {{count, number}} M compradores satisfechos',
  howElseCanWeImproveKarma: '¿De qué otra manera podemos mejorar Karma?',
  iNeedThisInMyLife: 'Lo necesito en mi vida',
  info: 'Info',
  itemUpdatesUnsubscribe: 'Actualizaciones de artículos (bajada de precios, reposiciones, etc.)',
  itsAGhostTown: 'Es un pueblo fantasma',
  joinTheTeam: 'Únete al equipo',
  karmaGetsYouTheLowestPossiblePrice: 'Karma te consigue el precio más bajo posible 👏 cada 👏 vez.',
  karmaIsAnApp: 'Karma es una aplicación y extensión que te ayuda a ahorrar tiempo y dinero mientras compras en línea.',
  karmaYourFavoriteBrands: '<1>Karma</1> tus marcas favoritas',
  karmaYourFavoriteBrandsMobile: '<1>Karma</1> tus marcas favoritas',
  knowHowToShopBetter: 'Aprende a <1>comprar mejor</1> que nadie',
  letsPlay: 'Juguemos',
  lolWereAVerbNow: '(ja, ja, ahora somos un verbo)',
  lookAtYouLittleScroller: 'Aquí no temerás perderte nada. Felicidades por llegar tan lejos.',
  lookAtYouLittleScrollerMobile: 'Aquí no temerás perderte nada.',
  lookingForInfluencer: '¿Buscas asociaciones publicitarias?',
  markItemsYouveMadeYourOwnItFulfillsSoGood: 'Marca los artículos que has creado. Cumple con lo que dice',
  markNumberItemsAsFullfilled: 'Vas a marcar {{value}} artículos como completados',
  meetYouAtIn: 'Nos vemos en {{retailer}} en 3 segundos...',
  missOut: 'te pierdas nada',
  missingYouAlready: '¡Ya te echo de menos!',
  money: 'dinero',
  never: 'Nunca',
  nevermind: 'Ok',
  noFomoHere:
    'Nada de miedo a perderse algo aquí. Te avisaremos cuando tus "deseos" bajen de precio o vuelvan a estar disponibles.',
  noNotificationsYet: 'Estáis atrapados',
  noNotificationsYet2: 'En cuanto haya una bajada de precio o una actualización de reposición, la encontrarás aquí.',
  nowLetsTalPrivacy: 'Ahora hablemos de <1>privacidad<1/>',
  nowLetsTalkAboutPrivacy: 'Ahora hablemos de privacidad 🤫',
  onlineShoppingButBetter: 'Compras en línea pero <1>mejores</1>',
  orCopyTheLinkBelowAndSend: 'O copia el enlace a continuación y envíalo a la vieja usanza.',
  other: 'Otro',
  passwordValidationNote:
    'Debe tener al menos 8 caracteres, incluyendo una letra mayúscula, una letra minúscula y un número.',
  payLess: 'Paga menos',
  playFavesWithYourItems: 'Juega a los favoritos con tus artículos',
  preparingYourKarmaCash: 'Preparando tu Karma Cash.',
  savingMoneyIsHotLetsGetOnThat: 'Ahorrar dinero está de moda. Empecemos con eso ✌️',
  search: 'Buscar',
  seeHowToSave: 'Descubre cómo ahorrar',
  skipForNow: 'Omitir por ahora',
  soFarWeSavedYou: 'Hasta ahora, has ahorrado con nosotros',
  sorryForInconvenience: 'Lamentamos las molestias. Prometemos que valdrá la pena.',
  starReviews: '👉 Más de {{count, number}} mil reseñas de 4,7 estrellas',
  starTheThingsYouNeedInYourLifeForQuickAccess: 'Marca con una estrella lo que más necesitas para acceder rápidamente.',
  stillWannaUnsubscribe: '¿Todavía quieres <1>cancelar la suscripción a todos los correos electrónicos de Karma</1>?',
  storePromotionsAndOtherMarketingEmails: 'Promociones de tienda y otros correos electrónicos de marketing',
  storesWorldwide: '👉 Más de {{count, number}} mil tiendas en todo el mundo',
  stuffWorthSavingGoesHere: 'Lo que vale la pena ahorrar está aquí 👇',
  sureYouWantToDeleteTheseNumberItems: '¿Seguro que quieres eliminar estos {{value}} artículos?',
  sureYouWantToDeleteThisList: '¿Seguro que quieres eliminar esta lista?',
  takeControlOfYourInbox: 'Toma el control de tu bandeja de entrada',
  theMotherOfAllWishLists: 'La madre de todas las <1>listas de deseos<1/>',
  thisFieldIsRequired: 'Este campo es obligatorio',
  toCashOutRewards: 'Para cobrar tus recompensas, debes alcanzar al menos ${{minimum, number}}.',
  toCashOutYouMustReach: 'Para cobrar tus recompensas, debes alcanzar al menos ${{currency}}{{value, number}}.',
  tryAgain: 'Inténtalo de nuevo',
  trySearchingForSomethingElse: 'Intenta buscar otra cosa.',
  updatePreferences: 'Actualizar preferencias',
  wantIt: 'Lo quiero',
  weAreBusySelfImproving: 'Estamos ocupados mejorándonos',
  weAreVerySeriousAboutPrivacy:
    'Nos tomamos muy en serio la privacidad y nunca venderemos tus datos a terceros. Nunca.',
  weDontSellYourData: 'No vendemos tus datos',
  weGotStuckOnOurWay: '¡Nos hemos quedado bloqueados de camino a {{retailer}}!',
  weHitYouUp: 'Te avisaremos cuando encontremos un código promocional para esta tienda.',
  weHustleForCoupons: 'Nos esforzamos por conseguir cupones',
  weUseBankLevelSecurity: 'Usamos seguridad a nivel bancario',
  wellApplyCodesThatSaveYouMoney: 'Aplicaremos códigos con los que ahorrarás $$$.',
  wellTrackAndScanForCoupons:
    'Rastrearemos y buscaremos cupones para que no tengas que hacerlo. <br/> (vale, no lo haremos, pero nuestra IA lo hará)',
  wellTrackAndScanForCouponsMobile: 'Haremos un seguimiento y buscaremos cupones para ahorrarte el esfuerzo.',
  wereAVerbNowLol: '(ahora somos un verbo, ja, ja)',
  wereGoingShopping: 'Vamos de compras',
  wereHereToMakeItYours: 'Estamos aquí para <1>#que_sea_tuyo</1>',
  whyUnsubscribeFromAllOurMoneySavingEmails:
    '¿Por qué cancelar la suscripción a todos nuestros correos electrónicos que te ahorran dinero si puedes desactivar solo aquellos que no son relevantes?',
  withKarmaYouCanBuy: 'Con Karma, puedes comprar<br /> lo que quieras y mantener contento al<br /> banquero.',
  withKarmaYourCash: 'Con Karma, tu dinero vuelve a ti como una especie de vudú: es dinero mágico.',
  yesDisconnect: 'Sí, desconectar',
  yesIDoThat: 'Sí, claro',
  youHaveToReconnectToWithdraw: 'Tendrás que volver a conectarte para retirar tu Karma Cash.',
  youNeedToSaveItemsBeforeYouCanShareYourList: 'Debes guardar los artículos para compartir tu lista.',
  youReATen: '¿Eres un 10 y pagas con Karma? Compra en línea y obtén el precio más bajo posible 👏 cada 👏 vez.',
  youReAboutToMuteNumberItems: 'Estás a punto de silenciar las notificaciones de {{value}} artículos',
  yourCashWillComeBackToYou: 'Tu dinero volverá a ti como una especie de vudú: dinero mágico. ',
  yourInformationIsSecured:
    'Tu información está protegida con el último cifrado de 256 bits para ofrecerte una protección total.',
  yourSearchCameUpEmpty: 'Tu búsqueda no tuvo resultados',
  total: 'Total',
  keepAnEyeOutForTheStatusUpdatesWeWillSendToEmail:
    'Estate al tanto de las actualizaciones de estado que enviaremos a {{email}}.',
  psaDidYouKnow: 'PD.: ¿sabías que puedes hacer todo esto?',
  addWishItemsFromAnyBrand:
    'Añadir artículos "deseados" de cualquier marca y esperar hasta que te digamos que es el momento de comprarlos.',
  capturePurchaseReadyShoppers: 'Expón tus mejores cupones y encuentra a compradores que están ya listos para comprar.',
  gainFullFunnelInsights: 'Conoce al detalle la intención de los compradores con cada artículo que se guarde.',
  boostCartEngagement:
    'Aumenta la tasa de utilización del carrito con nuestras actualizaciones de artículos en tiempo real.',
  promoteLongTermGrowth:
    'Promueve tu crecimiento a largo plazo con campañas personalizadas dentro del ecosistema de Karma.',
  reachNewAudiences: 'Llega a nuevas audiencias a través de diversas campañas adaptadas a tu marca',
  influencerCollaborations: 'Colaboraciones con influencers',
  teamUpWithTopInfluences: 'Forma equipo con los influencers que mejor encajen con la identidad de tu marca.',
  socialPosts: 'Publicaciones en redes sociales',
  giveANewFollowing: 'Consigue nuevos seguidores a partir de campañas personalizadas y llenas de contenido.',
  brandFocusedNewsletters: 'Boletines centrados en la marca',
  letYourOffersShine: 'Haz destacar tus ofertas en correos electrónicos seleccionados personalizados y muy esperados.',
  driveTrafficWhenYouNeedIt:
    'Conduce el tráfico hacia donde lo necesites con nuestras colocaciones de pago en la página de inicio',
  dashboardBanners: 'Banners de venta en el panel de control',
  flauntYourLatestOfferings: 'Haz alarde de tus últimas ofertas con elementos visuales llamativos.',
  dailyFeatured: 'Destacados diarios',
  highlightDeals: 'Resalta tus ofertas donde más importa.',
  dedicatedPopUps: 'Mensajes emergentes específicos',
  activelyServeYourPromos: 'Coloca activamente tus promociones para que nadie se las pierda.',
  createAnotherTouchpointForYourBrand: 'Crea otro punto de contacto para tu marca',
  increaseEngagement:
    'Aumenta las tasas de participación y conversión con las notificaciones push específicas en dispositivos móviles y la web.',
  karmaIsOnlineShoppingButBetter: 'Karma es comprar en línea, pero mejor.',
  transactionsNumber: '👉 Más de {{count, number}} millones de transacciones',
  partnershipsReview4: '¡Esto es fantástico para hacer tu lista de deseos y ahorrar dinero!',
  partnershipsReviewAuthor4: 'Emily Smith',
  partnershipsReview5:
    'Es literalmente la mejor extensión que he descargado. ¡Es lo mejor para ahorrar dinero y ganar dinero al mismo tiempo!',
  partnershipsReviewAuthor5: 'Selena Roblox',
  noNeedToSlideIntoOurDMs:
    'No tienes que colarte en nuestros DM (aunque puedes hacerlo 😏). Nuestro equipo está aquí para lo que necesites.',
  supportIssues: 'Problemas de asistencia',
  adsAndPartnerships: 'Anuncios y asociaciones',
  jobOpportunities: 'Oportunidades de empleo',
  publicRelations: 'Relaciones públicas',
  letsChat: 'Hablemos',
  earnings: 'Ingresos',
  allTime: 'Todo el tiempo',
  ordersMayTakeHoursToAppear: 'Los pedidos pueden tardar hasta {{value, number}} horas en aparecer.',
  youHaventReceivedAnyPayoutsYet: 'Aún no has recibido ningún pago ',
  alerts: 'Alertas',
  featuresReview1:
    'Añadí un par de zapatillas a mi lista de deseos y al día siguiente recibí una alerta que me avisaba de que tenía un 50 % de descuento. ¿Cómo es que compraba cosas siquiera antes de conoceros?',
  featuresReviewAuthor1: 'Sandra C. Martínez, usuaria de App Store',
  featuresReview2:
    'De verdad, esta es la mejor app de compras inteligentes, y eso que conozco otras. ¡Siento que estoy engañando un poco al sistema, porque sí que ahorro dinero! Siempre solía pagar el precio completo, y ahora me siento idiota',
  featuresReviewAuthor2: 'Gertie D. Ackerman, usuaria de App Store',
  featuresReview3:
    'Estaba comprando un nuevo portátil que ya tenía un 25 % de descuento. Cuando pulsé "pagar con Karma", vi que el precio final era aún más bajo. Pasa esto cada vez que pago con la app',
  featuresReviewAuthor3: 'Irene J. Cox, usuaria de App Store',
  downloadNow: 'Descargar ya',
  payingWithKarmaAlwaysGetsYouTheLowestPossiblePrices:
    'Pagar con Karma siempre te ofrece los <1> precios </1> más bajos posibles',
  wannaBeDeskBuddies: '¿Quieres que trabajemos juntos?',
  seeOpenPositions: 'Ver vacantes',
  weLiveAndSwearBy: 'Creemos en muchas cosas...',
  goodKarma: 'Buen Karma',
  weBelieveThatGoodVibes: 'Creemos que las ~buenas vibraciones~ son un estilo de vida, y así elegimos vivir.',
  beingTrue: 'Ser honestos',
  theresNoUSvsU:
    'No hay un "nosotros contra vosotros". Nuestras mesas son siempre redondas y te hemos guardado un sitio.',
  passion: 'Pasión',
  weHaveThatFireYouKnow: 'Tenemos esa chispa, ¿sabes? Funcionamos con impulso, persistencia y amor por lo que hacemos.',
  entrepreneurship: 'Emprendimiento',
  weWantItWeGetIt:
    'Lo queremos y lo entendemos. No perdemos un montón de tiempo en un sinfín de llamadas por Zoom. Vamos a por lo que queremos.',
  mutuality: 'Mutualidad',
  wereMadeUpOfAwesomePeople:
    'Contamos con personas increíbles que entienden que el trabajo en equipo no es suficiente. Nos preocupamos de verdad los unos por los otros.',
  buildCustomerLoyalty: 'Fideliza a tus clientes con recompensas en metálico al instante',
  itemSaving: 'Ahorro de artículos',
  itemUpdates: 'Actualizaciones de artículos',
  backInStockAlerts: 'Disponible de nuevo',
  lowInStockAlerts: 'Pocas existencias',
  storePromotions: 'Promociones de la tienda',
  exclusive: 'exclusiva',
  couponCopied: 'Cupón copiado',
  completedDesc:
    'La tienda ha confirmado tu pedido. Cualquier Karma Cash que ganes se puede retirar {{days, number}} días después de la fecha de compra y solo después de haber alcanzado el mínimo de {{currency}}{{amount, number}}.',
  notQualifiedDesc: 'Es posible que se apliquen restricciones adicionales a los artículos que has pedido.',
  pendingDesc:
    'La tienda ha recibido tu pedido. Puede aparecer como pendiente durante un máximo de {{days, number}} días, dependiendo de la política de devoluciones de la tienda.',
  cancelledDesc: 'La tienda nos ha informado de que tu pedido se ha cancelado o reembolsado en su totalidad.',
  downloadNowItsFree: 'Descárgala ya, es gratis',
  weLiveToMakeOurShoppersHappy: 'Vivimos para hacer felices a nuestros compradores',
  moneySavedForYou: 'Más dinero para ti',
  happyShoppers2: 'Compradores felices',
  starReviews2: 'Reseñas de {{value, number}} estrellas',
  storesWorldwide2: 'Tiendas en todo el mundo',
  whyYouWill: '¿Por qué lo harás',
  it: '?',
  multiBrandWishLists:
    '¿Listas de deseos de varias marcas? Auténticos escalofríos. Si ves algo que te gusta de cualquier marca, añádelo a tu lista de Karma y espera a que te digamos que es el momento de comprarlo.',
  addKarmaToYourMobile: 'Descarga<br />nuestra app<br />y hazte con<br />Karma para llevar',
  appStoreReview1:
    'Añadí una paleta de sombras de ojos a mi lista y al día siguiente recibí una notificación de que tenía un 50 % de descuento. ¡¡Gracias!!',
  appStoreReview2: 'Tener siempre el precio más bajo en mi bolsillo es muy peligroso. Pero también me encanta',
  appStoreReview3:
    'Acabo de encontrar el precio más bajo en unos auriculares nuevos mientras esperaba el autobús. Es demasiado fácil',
  appStoreReviewBy: 'Usuario de la App Store por: {{name}}',
  yourInfoSafeWithUs: 'Tu información está a salvo con nosotros',
  whyGiveOutYourCCDeets: '¿Por qué dar a conocer tu información cuando puedes guardarla en un solo lugar?',
  partyForOne: 'Una única parte',
  wellNeverSellYourDataToAnyThirdParties:
    'Nunca venderemos tus datos a terceras partes, punto. Tus datos están a salvo de miradas indiscretas gracias a nuestra seguridad de nivel bancario.',
  buyWithoutATrace: 'Compra sin dejar rastro',
  weWontLetAnyoneTrackYourShoppingHabits:
    'No dejaremos que nadie haga un seguimiento de tus hábitos de compra. Aquí no te juzgará nadie.',
  spotSomethingYouLoveFromAnyBrand:
    'Si ves algo que te guste de cualquier marca, añádelo a tu lista de Karma y espera a que te digamos que es el momento de comprarlo.',
  needIt: 'Lo necesito',
  appStoreReviewer: 'Usuario de App Store',
  appStoreReview4:
    'Confío en esta aplicación para hacer un seguimiento de todos los artículos que "quiero" en un solo lugar y conseguir el mejor precio posible.',
  appStoreReview5: 'Esta app me ha ayudado a ahorrar mucho dinero.',
  wishlist: 'Lista de deseos',
  getTheLowestPriceAtAllYourFavoriteBrands: 'Consigue los precios más bajos en todas tus marcas favoritas.',
  getTheAppForFree: 'Consigue la app gratis',
  addKarmaSpaced: 'Añade Karma<br />a tu escritorio<br />para poder comprar<br />en horas de trabajo.',
  spotSomethingYouLoveFromAnywhere:
    'Si ves algo que te gusta de cualquier marca, añádelo a tu lista de Karma y espera a que te digamos que es el momento de comprarlo.',
  getCouponsCashbackWithPeriod:
    'Recibe cupones, reembolsos en efectivo y alertas de bajadas de precios.<br />Paga siempre el precio más bajo.',
  getCouponsCashbackWithAnd:
    'Recibe cupones, reembolsos en efectivo, alertas de bajadas de precios <br /> y para siempre el precio más bajo.',
  clickTheLoginLinkFromYourPhone:
    'Haz clic en el enlace de inicio de sesión desde el móvil para acceder a la aplicación de Karma.',
  getItOn: 'Súbete',
  downloadOnThe: 'Descárgala en',
  likeShoppingFromYourComputer: '¿Te gusta comprar desde el ordenador?',
  getTheKarmaExtensionToShopEvenBetter: 'Consigue la extensión Karma para comprar aún mejor.',
  headerMobileLogin: 'Karma | Instalar',
  noFomoHereMobile:
    'No te perderás nada. Te avisaremos cuando tus "deseos" bajen de precio o vuelvan a estar disponibles.',
  withKarmaYourCashMobile: 'Con Karma, tu dinero vuelve a ti como una especie de vudú: es dinero mágico.',
  spotSomethigYouLoveMobile:
    'Si ves algo que te gusta de cualquier marca, añádelo a tu lista Karma y espera a que te digamos que es el momento de comprarlo.',
  wellTrackAndScanForCouponsMobile2:
    'Rastrearemos y buscaremos cupones para que no tengas que hacerlo tú (vale, nosotros no, pero sí nuestra IA).',
  weHustle: 'Encontramos rápidamente',
  forCoupons: 'los <1>cupones</1>',
  theMotherOfAll: 'La madre de todas',
  wishlists2: 'las listas de deseos',

  // NEW PHRASES
  moneySavedForUsers: 'Money saved for users',
  whatItsLikeWorkingAtKarma2: 'What’s it like working at Karma?',
  makeItYours: 'Make it yours',
  continueWith: 'Continue with {{provider}}',
  onItsWay: 'On its way...',
  weVeEmailedInstructionsForResettingYourPassword:
    'We’ve emailed instructions for resetting your password to {{email}}',
  wrongEmailTryAgain: 'Wrong email? Try again',
  goToEmail: 'Go to email',
  heyYouLetsJumpBackIntoSaving: 'Hey you! Let’s jump back into saving',
  firstConfirmItsYou: 'First, confirm it’s you',
  letsDoThis: 'Let’s do this',
  signUpAndGet: 'Sign up and get',
  moneyOnUs: '{{currency}}{{value, number}} on us',
  usersHeroTitle: 'Your\nbank account’s gonna love this.',
  tellMeMore: 'Tell me more',
  createNewPassword: 'Create new password',
  joinKarma: 'Join Karma',
  saveItemsFromStores: 'Save items from your favorite stores with a single click',
  easilySaveAndKeep:
    'Easily save and keep track of everything you want to buy. The best\npart? You’ll get notified the second any of your saved items\ngo on sale, or come back in stock.',
  addTheFreeKarma:
    "Add the free Karma button to your browser so you can\neasily keep track of everything you want to buy. The best\npart? You'll get notified the second any of your saved items\ngo on sale, drop in price, or come back in stock.",
  getButtonAndGetEverything: 'Get the free Karma button and\nget everything you want on sale',
  getKarmaItIsFree: "Get Karma - It's Free!",
  onYourPhone: 'On your phone? Save items on the go with our free app',
  hereHowTheButtonWorks: "Here's how the Karma\nbutton works",
  dontSellMyPersonalInformation: 'Don’t sell my personal information',
  postCode: 'Postcode',
  ifThisWontHelp: "If this won't help, ",
  contactSupport: 'contact our support center.',
  wellKeepAnEyeOnIt: 'We’ll keep an eye on it & will let u know ASAP if the price drops',
  seeYourSavedItems: 'See your saved items',
  logInAndSaveItemToKarma: 'Login & Save item to Karma',
  blogPromotions: 'Blog promotions',
  expiringIn: 'Expiring in',
  goToStore: 'Go to store',
  visitStorePage: 'Visit store page',
  favStoresUpdated: 'Your favorite stores were successfully updated.',
  availableCoupons_plural: '{{count}} available coupons',
  availableCoupons: '{{count}} available coupon',
  findAndComparedTheBestDeals: 'Finds and compares the best deals',
  karmaHelpsYouShopSmarter: 'Karma helps you shop smarter by instantly comparing products from different retailers.',
  expiring: 'Expiring',
  today: 'Today',
  tomorrow: 'Tomorrow',
  days: '{{count, number}} DAY',
  weeks: '{{count, number}} WEEK',
  months: '{{count, number}} MONTH',
  daysPlural: '{{count, number}} DAYS',
  weeksPlural: '{{count, number}} WEEKS',
  monthsPlural: '{{count, number}} MONTHS',
  tellUsWhyYouUninstalledTheExtension: 'Tell us why you uninstalled the extension',
  tooManyPopUpsWhileIShop: 'Too many pop-ups while I shop',
  couponsDontWork: 'Coupons don’t work',
  dataPrivacyConcerns: 'Data privacy concerns',
  causedProblemsWithMyBrowser: 'Caused problems with my browser',
  tellUsMoreOptional: 'Tell us more (optional):',
  shareYourThoughtsWithUs: 'Share your thoughts with us',
  myFavoriteStoresAreNotSupported: 'My favorite stores are not supported',
  notReadyToLetGoYet: 'Not ready to let go yet? Reinstall Karma with just one click',
  chatWithUsAndGet: 'Chat with us & get<br />${{count}} Amazon Gift Card',
  scheduleAMeetingAndEarnGiftCard:
    'Schedule a {{minutes}}-minute interview and earn a<br />${{count}} Amazon gift card.',
  scheduleAnInterview: 'Schedule an interview',
  compareProductsBeforeYouBuy: 'Compare products before you buy',
  downloadWithKarmaAndSaveMajorCash: 'Download Karma<br/>and save major $$$',
  youSearchedFor: 'You searched for',
  yourSavedItems: 'Your saved items',
  moreForYou: 'More for you',
  headerGlobalSearch: 'Karma | {{query}}',
  noResultsFoundFor: 'No results found for',
  pleaseReviewYourSpellingOrTryDifferentKeyword: 'Please review your spelling or try different keyword',
  heyThere: 'Hey, {{name}}',
  myAccount: 'My account',
  accountSettings: 'Account settings',
  trendingNow: 'Trending now',
  helpUsImproveKarma: 'Help us improve Karma',
  yourFavoriteStores: 'Your favorite stores',
  chooseYourFavoriteStores: 'Choose your favorite stores',
  viewStoreInfo: 'View store info',
  addStore: 'Add store',
  findMoreStores: 'Find more stores',
  whatAreYouShoppingFor: 'What are you shopping for?',
  searchForAnyItemBrandOrStore: 'Search for any item, brand or store',
  savedFromStore: 'Saved from store',
  manageFavoriteStores: 'Manage favorite stores',
  hasNoActiveCoupons: '{{retailer}} has no active coupons',
  checkOutOtherStores: 'Check out other stores for more deals',
  youCanStillEarn: 'You can still earn {{number}}% cashback with Karma!',
  retailerCoupons: '{{retailer}} coupons',
  nothingSavedYet: 'Nothing saved yet?',
  useOurBrowserExtension:
    'Use our browser extension on your {{retailerName}} visit to quickly add items to your favorites. Your shopping journey starts here!',
  letsMakeItPersonal: 'Let’s make it personal',
  selectAndTrack: 'Select your favorite stores and track coupons and deals',
  chooseYourFaves: 'Choose your faves',
  code: 'Code',
  itemFound: '{{count, number}} item found',
  itemFound_plural: '{{count, number}} items found',
  storeFound: '{{count, number}} store found',
  storeFound_plural: '{{count, number}} stores found',
  itemsTitle: 'Items',
  storeInfo: 'Store info',
  preOwned: 'Pre-owned',
  refurbished: 'Refurbished',
  favorited: 'Favorited',
  allPromotionsOrOffers:
    "All promotions or offers by any of our partners are subject to the applicable partner's terms and conditions, which may be changed by the partner at its discretion. We may receive a commission for purchases made through our links or using our coupons. Eligibility for a welcome bonus is subject to section 5 of Karma’s Terms & Conditions.",
  shippingInfo: 'Shipping info.',
  freeShipping: 'Free shipping',
  overMinAmount: 'over {{currency}}{{value, number(minimumFractionDigits: 2)}}',
  delivers: 'Delivers',
  deliveryDays: '{{value, number}} days',
  freeReturns: 'Free returns',
  upToDays: 'up to {{value, number}} days',
  deliveryInfo: 'Delivery info.',
  returnsInfo: 'Returns info.',
  notAvailable: 'Not available',
  minOrderMayApply: 'Min. order may apply',
  hottestDeals: 'Hottest deals',
  discoverCouponsAndExclusiveOffers: 'Discover coupons and exclusive offers',
  moreFromStore: 'More from {{store}}',
  seeWhatsTrendingNow: 'See what’s trending now',
  automaticallyApplyCoupons:
    'Automatically apply coupons at checkout, save what you love and earn cashback — all with the Karma extension.',
  deleteMyAccount: 'Delete my account',
  areYouSureDeleteAccount: 'Are you sure you want to delete your account?',
  youHaveKarmaCash: 'You have {{currency}}{{value, number}} Karma Cash in your wallet.',
  deletingYourAccountWill:
    'Deleting your account will permanently remove all of your information, your saved items and your earnings from our database, and this action cannot be undone.',
  cancel: 'Cancel',
  accountDeletedSuccessfully: 'Account deleted successfully',
  shopOnTheGo: 'Shop on-the-go with Karma app',
  moveFromDevice: 'With Karma, effortlessly move from one device to another as you shop.',
  fewMomentsWeAreStillWorkingOnSavingItemsToThisList:
    'Few moments, we’re still working on saving items to this list... ',
  notificationDate: '{{date, shortFromNowDate}} ago',
  onboardingIOSTitle: 'Enable Karma on Safari \n to access our coupon scanner',
  enableKarmaOnSafari: 'Enable Karma on Safari to access our coupon scanner',
  alwaysAllowOnSites: 'Always allow on all sites to get access to coupons',
  iosOnboardingFlowSubtitle: '{{step, number}}/{{numberOfSteps, number}} {{text}}',
  extension: 'extension',
  howToEnable: 'How to enable',
  howToGetCoupons: 'How to get coupons',
  sweOnboardingStep1_1: 'Tap the <1>A</1><2>A</2> in the URL bar',
  sweOnboardingStepAlt1_1: 'Tap the aA in the URL bar',
  sweOnboardingStep1_2: 'Select “Manage Extensions”',
  sweOnboardingStep1_3: 'Toggle on',
  sweOnboardingStep2_1: 'Tap the <1>A</1><2>A</2> in the URL bar again',
  sweOnboardingStep2_2: 'Tap the',
  sweOnboardingStep2_3: 'Select “Always allow”',
  sweOnboardingStep2_4: 'Select “Always allow on every website”',

  sweOnboardingStep2_5_1: 'Go back to the',
  sweOnboardingStep2_5_2: 'Karma app',
  shopSmarter: 'Shop smarter with\nthe power of AI',
  joinOurUsers: 'Join our {{number, number}} million users\nwho are shopping\nsmarter from all their\ndevices',
  browseAndBuy:
    'Browse and buy from our desktop extension or\nuse the Karma app to ensure you’re always\ngetting the most out of your purchase.',
  joinCard1: '<0>{{value}}M+</0><1>shoppers</1>',
  joinCard2: '<0>15K</0><1>5 star reviews</1>',
  joinCard3: '<0>$5 cash</0><1>at sign-up</1>',
  joinCard4: '<0>2.3 million</0><1>mobile app users</1>',
  joinCard5: '<0>$7.50</0><1>avg order savings</1>',
  joinCard6: '<0>50 million</0><1>items tracked</1>',
  whatMakesKarmaGood: 'What makes Karma\nso good?',
  karmaGoodTitle1: 'Karma let’s you shop\nsmarter from anywhere',
  karmaGoodDescription1:
    'Karma’s browser extension meets you\nwherever you shop. Shop directly in the Karma\napp or share the items you want to Karma.',
  karmaGoodTitle2: 'Helps you plan your\nnext purchases',
  karmaGoodDescription2:
    'Not ready to buy just yet? Save it for later in the\nKarma app or share it to Karma and shop more\nmindfully. You can keep an eye on all your\nsaved items in one place.',
  karmaGoodTitle3: 'Finds and compares the\nbest deals',
  karmaGoodDescription3: 'Karma helps you shop smarter by instantly\ncomparing products from different retailers.',
  karmaGoodTitle4: 'So you can buy at the\nright time',
  karmaGoodDescription4:
    "Karma uses clever technology to track if the\nprice drops, or when it's back in stock, so you\ncan buy at the right time.",
  onlyOnMobile: 'Only on the mobile app',
  makeYourInspo: 'Make your inspo\na reality',
  easilyBringYourFavorite:
    'Easily bring your favorite social media looks\ninto your life, just share the posts to Karma and\nour AI will scan the image and find the items\nthat match.',
  empowerTouToMake: 'Empower you to\nmake the right\nchoices at the right\ntime when you shop\nonline.',
  dontTakeOurWord: 'Don’t take our word for it',
  totalSavings: 'Total savings',
  joinOurUsersDesktop: 'Join our {{number, number}} million users who are\nshopping smarter from all their devices',
  andAutomaticallyFindsYouCouponsAtCheckout: 'And automatically finds you coupons at checkout',
  clickAlwaysAllowOnEveryWebsite: 'Click <2>“Always Allow on Every Website“</2> so we can work on all your fave sites',
  weNeedYourPermissionToAutomaticallyShowYouTheBestDeals:
    'We need your permission to automatically show you the best deals on all your favorite shopping websites.',
  yourPrivacyAndSecurityIsImportantToUs: 'Your privacy and security are important to us - it’s in our',
  whileKarmaWillStillWork: 'While Karma will still work, it’ll require more effort on your part to find deals.',
  unfortunatelyWithoutTheAlwaysAllowPermission:
    'Unfortunately, without the “Always Allow on Every Website” permission you’ll have to manually click Karma whenever you shop',
  noThanksSignUpForAnAccount: 'No thanks, Sign up for an account',
  logInWithEmail: 'Log in with email',
  signUpWithEmail: 'Sign up with email',
  iAgreeToTermsOfUseAndPrivacyPolicy: 'I agree to <1>Terms of Use</1> and <3>Privacy Policy</3>',
  joinOurEnergeticTeam: 'Join Our Energetic Team',
  atKarmaWeReNotJustAboutWork:
    'At Karma, we’re not just about work; we’re about creating an environment where everyone’s input is valued and where each day brings a new opportunity for growth and impact.',
  beAPartOfTheJourney: 'Be a part of the journey',
  noPositionsAvailable: 'No positions available',
  apply: 'Apply',
  dontSeeAnythingThatFitsYourSkillSet:
    "Don't see anything that fits your skill set? Don't let that stop you from reaching out! If you think you can help us grow, send an email to",
  ifYouReReadyToMakeAnImpact:
    'If you’re ready to make an impact and thrive in a setting that values speed, fun, and collaboration, Karma might be your next great adventure.',
  exploreOurCareerOpportunities:
    'Explore our career opportunities and find out how you can contribute to our exciting journey ahead. Join us, and let’s achieve greatness together!',
  welcomeToKarma:
    'Welcome to Karma—where technology meets savvy shopping. Join us in our mission to revolutionize the way you shop, ensuring every purchase is just right for you.',
  empoweringSmartShopping: 'Empowering Smart Shopping:\nThe Karma Story',
  ourMissionPart1:
    'At Karma, we believe in empowering shoppers to make wise and informed purchasing decisions. Founded in 2015, our mission has always been to guide consumers toward smarter, more responsible shopping. As both a browser extension and mobile app, Karma harnesses the power of advanced AI to enhance your shopping experience. This technology predicts the optimal time to make a purchase, ensuring you get the best deal possible.',
  ourMissionPart2:
    'Over the years, Karma has continuously evolved, integrating cutting-edge technology to keep pace with the dynamic e-commerce landscape. We take pride in helping our users avoid buyer’s remorse and overspending by providing them with the tools they need to shop confidently!',
  hearFromOurUsers: 'Hear from our users',
  karmaPatents: 'Karma patents',
  systemAndMethodForPreauthorizing: 'System and method for preauthorizing database transaction commits',
  systemAndMethodForScraping: 'System and method for near real time web scraping',
  couponsHandled: 'Coupons? Handled',
  skipCouponHunt: 'Skip the coupon hunt. We’ll automatically apply the best discounts for you at checkout.',
  smartAlerts: 'Smart alerts for your wishlist',
  saveItemsGetAlerted: 'Save items and get alerted when prices drop or items are back in stock.',
  cashbackMadeSimple: 'Cashback made simple',
  earnMoneyBack: 'Earn money back on your purchases, turning shopping into savings.',
  startYourShoppingJourney: 'Start your shopping journey',
  searchForItemBrandStore: 'Search for an item, brand or store',
  quillDisclosure: 'HP, Epson, & Samsung excluded',
  theKButtonIsInMyWay: 'The K button is in my way',
  itWasntHelpful: 'It wasn’t helpful',
  reinstallTheExtension: 'Reinstall the extension',
  tooManyCouponPopupsAtCheckout: 'Too many coupon pop-ups at checkout',
  didYouKnowYouCanHideTheKButtonAndDisablePopupsFromYourSettings:
    'Did you know you can <1>hide</1> the K button and <3>disable</3> pop-ups from your settings?',
  theHardPartIsOverNowLetsMakeTheMostOfYourShopping: "The hard part's over — now let’s make the most of your shopping!",
  goToYourFavoriteRetailerToSeeKarmaInAction: 'Go to your favorite retailer to see Karma in action.',
  howToSaveItemsWithKarma: 'How to Save Items with Karma',
  justGoToAnyProductPageOnYourFavoriteSiteAndHitThe:
    'Just go to any product page on your favorite site and hit the bookmark icon next to the "K".',
  peekAtYourWishlistAnytime: 'Peek at Your Wishlist Anytime',
  tapTheKAndHeadToTheMyKarmaTabToSeeYourPicks: 'Tap the "K" and head to the “My Karma” tab to see your picks.',
  watchOurCouponScannerInAction: 'Watch Our Coupon Scanner in Action',
  atCheckoutSeeOurCouponScannerTryOutEveryCodeWeveFoundForYou:
    'At checkout, see our coupon scanner try out every code we’ve found for you.',
  hereToHelpNotToHinder: 'Here to Help, Not to Hinder',
  wantACleanerLook: 'Want a cleaner look? Easily hide any feature by turning it off\nfrom your settings.',
  takeKarmaWithYou: 'Take Karma with You',
  downloadTheKarmaAppToBuildYourWishlistAndReceiveAlertsWhereverYouAre:
    'Download the Karma app to build your wishlist and receive alerts wherever you are. Never miss a beat!',
  justGoToAnyProductPageOnYourFavoriteSiteAndHitTheSaveButtonInTheKarmaExtension:
    'Just go to any product page on your favorite site and hit the save button in the Karma extension.',
  tapTheKAndHeadToTheSavedTabToSeeYourPicks: 'Tap the “K" and head to the “Saved” tab to see your picks.',
  cookies:
    'If you accept cookies, we’ll use them to improve and customize your experience and enable our partners to show you personalized ads when you visit other sites. <2>Manage cookies and learn more</2>',
  decline: 'Decline',
  confirmYourCookieSettings: 'Confirm your cookie settings',
  youControlHowWeUseCookies:
    'You get to control how we use cookies on each device and browser you use. These settings will apply to your current device when you use {{browser}}.',
  whatAreCookies: 'What are cookies?',
  cookiesAndTrackingTechnologies:
    'Cookies and tracking technologies are small text files stored on your device when you use a web browser. Some cookies are essential for you to use our site, while other cookies collect data about your browsing habits. We use this data to give you the best experience.',
  marketing: 'Marketing',
  weUseMarketingCookies: "We use marketing cookies to deliver ads we think you'll like.",
  marketingCookiesLet: 'For example, marketing cookies let us show you personalized ads based on your interests.',
  performance: 'Performance',
  weUsePerformanceCookies: 'We use performance cookies to understand how you interact with our site.',
  performanceCookiesHelp:
    'For example, performance cookies help us learn which parts are the most popular and which parts we could improve for you.',
  functional: 'Functional',
  weUseFunctionalCookies: 'We use functional cookies to customize your experience.',
  functionalCookiesLet:
    'For example, functional cookies let us remember your preferences like language, country or region, and text sizes.',
  essential: 'Essential',
  weUseEssentialCookies: 'We use essential cookies to make our site work for you.',
  essentialCookiesLet:
    'For example, essential cookies let you securely sign in and browse our site. These cookies help us keep your account safe and prevent fraud.',
  youCanReadMoreAboutCookies: 'You can read more about how we use cookies in our <2>Cookie Statement</2>.',
  confirmCookieSettings: 'Confirm Cookie Settings',
  noMinimumOrderValue: 'No minimum order value',
  fromToDays: '{{value, number}} to {{value2, number}} days',
  headerLogin: 'Karma | Login',
  headerOnboarding: 'Karma | Onboarding',
  yourDataHasBeenRemoved: 'Your data has been successfully removed.',
  confirmationCode: 'Confirmation code: {{code}}',
};
