import { isMobile } from 'functions/isMobile';
import * as ALOOMA from 'consts/alooma';
import isTablet from 'functions/isTablet';

const getOrigin = () => {
  if (isMobile()) {
    return ALOOMA.ORIGIN.MOBILE;
  }

  if (!isTablet()) {
    return ALOOMA.ORIGIN.DESKTOP;
  }

  return ALOOMA.ORIGIN.TABLET;
};

export default getOrigin;
