import styled, { css } from 'styled-components';
import { Stack, Typography } from '@karma/components';
import { getColor } from '@karma/components/colors';

export const ButtonsContainer = styled(Stack)`
  width: 360px;
`;

export const EmailAuthorizationWrapper = styled.div`
  padding: 40px 80px;
  width: 100%;
  height: 100%;

  .back-button {
    position: absolute;
    top: -20px;
    left: -60px;
  }
`;

export const ContentContainer = styled(Stack)`
  padding: ${({ $isGDPRCountry }) => ($isGDPRCountry ? '16px 20px' : '40px 20px')};
  height: 100%;
  color: ${getColor('primary2')};
`;

export const Title = styled(Typography)`
  max-width: 360px;
`;

export const TabsContainer = styled(Stack)`
  padding: 0 40px;
`;

export const TermsAndConditionsText = styled(Typography).attrs(attrs => ({
  ...attrs,
  variant: 'labelSmall',
}))`
  user-select: none;
  text-align: center;

  ${({ $smallWidth }) =>
    $smallWidth &&
    css`
      max-width: 360px;
    `}

  a {
    color: inherit;
  }
`;
