import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as ALOOMA from 'consts/alooma';
import { getLoaded, getGuest } from 'store/user/selectors';
import trackFacebookEvent from 'functions/trackFacebookEvent';
import Modal from 'components/templates/Modal/Modal';
import ModalBodyAuthenticate from 'components/molecules/ModalBodyAuthenticate/ModalBodyAuthenticate';
import { AUTHENTICATE_TYPE } from 'consts/authenticateModal';

const mapAloomaModalSource = {
  [AUTHENTICATE_TYPE.LOGIN]: ALOOMA.SOURCE_PAGES.MODAL_LOGIN,
  [AUTHENTICATE_TYPE.SIGN_UP]: ALOOMA.SOURCE_PAGES.MODAL_COMPLETE_SIGN_UP,
};

const ModalCompleteSignUp = props => {
  const { closeModal } = props;

  const userLoaded = useSelector(getLoaded);
  const userIsGuest = useSelector(getGuest);
  const [authState, setAuthState] = useState(AUTHENTICATE_TYPE.SIGN_UP);

  const successSignUp = () => {
    closeModal();
    trackFacebookEvent('CompleteRegistration');
  };

  useEffect(() => {
    if (userLoaded && !userIsGuest) {
      successSignUp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsGuest, userLoaded]);

  return (
    <Modal
      source={mapAloomaModalSource[authState]}
      aloomaModalType={ALOOMA.MODAL_TYPES.WINDOW}
      aloomaModalName={mapAloomaModalSource[authState]}
      onClose={closeModal}
    >
      <ModalBodyAuthenticate {...props} authState={authState} setAuthState={setAuthState} isGuestSignup />
    </Modal>
  );
};

ModalCompleteSignUp.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalCompleteSignUp;
